import React, { useState, useEffect } from 'react';
import { Card, Dropdown } from 'react-bootstrap';
import {
  DropDownIcon,
} from '../../shared/NewIcon';
import Generation from '../../app/GenerationPage/Generation';

const RecentTemplate = ({videoTemplates,isDashboard,handleViewAll}) => {
  const [playingVideoId, setPlayingVideoId] = useState(null);
 
  const handleVideoPlay = (templateId) => {
    setPlayingVideoId(templateId);
  };

  return (
    <div className="mb-5">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h2 className="font24-medium-primary">{isDashboard ? 'New Templates' : 'Recent Templates'}</h2>
        {isDashboard && (
          <div className="font14-medium-primary" onClick={handleViewAll} style={{ cursor: 'pointer' }}>
            view all
          </div>
        )}
      </div>

      <div className="row g-4">
        {videoTemplates &&
          videoTemplates.length > 0 &&
          videoTemplates.map((template) => (
            <div key={template._id} className={'col-sm-12 col-md-6 col-lg-4 col-xl-3'}>
              <Card
                className="cursor-pointer"
                style={{
                  border: 0,
                }}
              >
                <Card.Body className="p-0">
                  <div
                    className="video-container"
                    style={{
                      position: 'relative',
                      width: '100%',
                      backgroundColor: '#000',
                      borderRadius: '5px',
                    }}
                  >
                    {playingVideoId === template._id ? (
                      <video
                        width="100%"
                        height="100%"
                        style={{
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          borderRadius: '5px',
                        }}
                        controls
                        autoPlay
                      >
                        <source src={template.url} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <>
                        <img
                          src={template?.coverImageUrl}
                          alt="Video Thumbnail"
                          style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            objectFit: 'fill',
                            borderRadius: '5px',
                          }}
                        />

                        <button
                          className="play-button"
                          onClick={(e) => {
                            e.stopPropagation(); // Prevent card selection
                            handleVideoPlay(template._id);
                          }}
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            background: 'transparent',
                            border: 'none',
                            borderRadius: '50%',
                            padding: '10px 15px',
                            color: '#fff',
                            cursor: 'pointer',
                          }}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="32" height="32">
                            <circle cx="32" cy="32" r="32" fill="#f1482d" />
                            <polygon points="25,18 25,46 46,32" fill="#fff" />
                          </svg>
                        </button>
                      </>
                    )}
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-2 font12-semibold-primary">
                    <div>{template.name}</div>
                    <div className="generation-action">
                      <Dropdown>
                        <Dropdown.Toggle variant="" id="dropdown-basic" className="bg-transparent">
                          <DropDownIcon />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item href="#/action-3">Edit</Dropdown.Item>
                          <Dropdown.Item href="#/action-1">Rename</Dropdown.Item>
                          <Dropdown.Item href="#/action-2">Share</Dropdown.Item>
                          <Dropdown.Item href="#/action-3">pin</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
      </div>
    </div>
  );
};

export default RecentTemplate;
