import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { Dropdown, Modal, Nav, Table } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import defaultAvtar from '../../assets/images/default-avtar.png';
import PlaceholdeImage from '../../assets/images/Logo.png';
import { useTourContext } from '../../context/TourContext';
import User from '../../context/UserContext';
import { AllPresentationIcon, SearchIcon, UserIcon } from '../../shared/Icons';
import Spinner from '../../shared/Spinner';
import AxiosRequest from '../../utils/AxiosRequest';
import {
  DeleteIcon,
  DescriptionIcon,
  DropDownIcon,
  EditIcon,
  FavouriteIcon,
  FavouriteStRockIcon,
  GridViewIcon,
  ListViewIcon,
  NotificationIcon,
  PresentationIcon,
  SettingIcon,
  StartIcon,
  VideoIcon,
} from '../../shared/NewIcon';
import NotificationComponent from '../../components/Dashboard/Notification';
import PropertyImagePlaceholder from '../../assets/images/propertyimageplaceholder.png';
import { formatDate } from '../../utils/common';
import RecentTemplate from '../../components/Dashboard/RecentTemplate';

const Generation = ({ setActiveItem }) => {
  const [userProperty, setUserProperty] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [totalGenerations, setTotalGenerations] = useState(null);
  const navigate = useNavigate();
  const { currentUser, _currentUser, currentWorkspace, setCurrentWorkspace, handleSelectCurrentWorkspace } =
    useContext(User);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { setState, state } = useTourContext();
  const [viewType, setViewType] = useState('grid');

  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState({ search: searchTerm });
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isGeneration = queryParams.get('isgeneration');
  const [videoTemplates, setVideoTemplates] = useState([]);
  const isDashboard = true;

  useEffect(() => {
    const handler = setTimeout(() => setFilter((prev) => ({ ...prev, search: searchTerm })), 500);
    return () => clearTimeout(handler);
  }, [searchTerm]);

  // Fetch video templates only when currentWorkspace changes.
  useEffect(() => {
    if (currentWorkspace) {
      fetchVideoTemplates();
    }
  }, [currentWorkspace]);

  // Fetch user properties when any of these dependencies change.
  useEffect(() => {
    if (currentWorkspace) {
      getUserProperties();
    }
  }, [filter, page, currentWorkspace, isGeneration]);

  useEffect(() => {
    if (currentUser && !currentUser?.tourTaken && state.stepIndex === 0) {
      setShowCancelModal(true);
    } else {
      setShowCancelModal(false);
    }
  }, [currentUser]);

  const fetchVideoTemplates = async () => {
    try {
      const response = await AxiosRequest.get('/property/video-template');
      setVideoTemplates(response?.data?.data || []);
    } catch (error) {
      console.error('Error fetching video templates:', error);
    }
  };
  const getUserProperties = async () => {
    try {
      setIsLoading(true);
      const result = await AxiosRequest.get(
        `/property?page=${page}&limit=8&workSpace=${currentWorkspace?._id}&search=${filter.search}`
      );

      if (page === 1) setUserProperty(result.data.data.data);
      else setUserProperty((prevProperties) => [...prevProperties, ...result.data.data.data]);

      setTotalGenerations(result.data.data.totalCount);
      setIsLoading(false);
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      setIsLoading(false);
    }
  };

  const handleViewAll = () => {
    setActiveItem('Templates');
  };

  const handleReadMore = (data) => {
    // if (data?.socialDescription || data?.videoUrl) {
    navigate(`/property-details/${data._id}`);
    // }
  };

  const handleTour = () => {
    setShowCancelModal(false);
    setState((prevState) => {
      return {
        ...prevState,
        run: true,
        tourActive: true,
      };
    });
    // setTimeout(() => {
    //   navigate("/my-generation")
    // }, 300)
  };

  const closeModal = async () => {
    await AxiosRequest.post('/user/tourCompleted');
    _currentUser();
    setShowCancelModal(false);
  };

  return (
    <>
      {isLoading && <Spinner />}
      <div id="generated-content" className="pricing">
        {/* <BannerHeader heading="My Listings" id={undefined} /> */}
        <div className="mt-3 d-flex justify-content-between w-100">
          <div id="generate" className="generate_header">
            <div className="generate_header_filter w-100 justify-content-between">
              <div>
                {userProperty.length === 0 && !isLoading && (
                  <div className="plan-text fs30 w-100 text-start mb-3">Welcome to Restate Copilot</div>
                )}
              </div>
              <div className="searchInputContainer">
                <SearchIcon className="searchIcon" />
                <input
                  type="search"
                  className="searchInput"
                  placeholder="Search your files here using address"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>

              <div>
                <div className="d-flex">
                  <span className="me-3">
                    <div className="icon-bg">
                      <SettingIcon />
                    </div>
                  </span>
                  <span>
                    <NotificationComponent />
                  </span>
                </div>
              </div>
            </div>
            {/* <GenerateButton /> */}
          </div>
        </div>

        <div className="dashboard">
          {!userProperty && (
            <div>
              <RecentTemplate videoTemplates={videoTemplates} isDashboard={isDashboard} handleViewAll={handleViewAll} />
            </div>
          )}
          <div className="d-flex justify-content-between align-items-center mb-3">
            {userProperty.length !== 0 && !isLoading && (
              <>
                <div className="font24-medium-primary">My designs</div>
                <div>
                  <div className="view-toggle">
                    <div
                      onClick={() => setViewType('grid')}
                      className={`icon-wrapper ${viewType === 'grid' ? 'active' : ''}`}
                      title="Grid View"
                    >
                      <GridViewIcon />
                    </div>
                    <div
                      onClick={() => setViewType('list')}
                      className={`icon-wrapper ${viewType === 'list' ? 'active' : ''}`}
                      title="List View"
                    >
                      <ListViewIcon />
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          {viewType === 'grid' ? (
            <div className="row">
              {userProperty.length > 0 ? (
                userProperty.map((data, index) => {
                  return (
                    <div className="col-md-12 col-lg-6 col-xl-3  mb-3" key={index}>
                      {/* <div className="col-md-12 col-lg-6 col-xl-4  mb-3" key={index} onClick={() => handleReadMore(data)}> */}

                      <div>
                        <div className="mask-group-parent custom">
                          {data?.propertyImages?.[0]?.images?.[0] ? (
                            <div className="imageContainer2" onClick={() => handleReadMore(data)}>
                              <img className="img-fluid cover" alt="" src={data?.propertyImages?.[0]?.images?.[0]} />
                            </div>
                          ) : (
                            <div className="imageContainer2" onClick={() => handleReadMore(data)}>
                              <img className="img-fluid cover" alt="" src={PropertyImagePlaceholder} />
                            </div>
                          )}
                        </div>
                        {/* {data?.coverImage ? (
                      <div className="imageContainer2">
                        <img className="img-fluid cover" alt="" src={data?.coverImage} />
                      </div>
                    ) : (
                      <div className="imageContainer2">
                        <img className="img-fluid contain" alt="" src={PlaceholdeImage} />
                      </div>
                    )} */}
                        <div className="frame-parent-generation w-100">
                          <div className="d-flex flex-wrap justify-content-between align-items-start w-100">
                            <div
                              className="d-flex flex-column align-items-start"
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleReadMore(data)}
                            >
                              <div className="font14-semibold-primary">{data.name}</div>
                              {/* <div className="title-here-wrapper">
                              <div className="home date">
                                {data?.createdAt ? moment(data.createdAt).format('MMMM DD YYYY') : '-'}
                              </div>
                            </div> */}
                            </div>
                            <div className="generation-action">
                              <Dropdown>
                                <Dropdown.Toggle variant="" id="dropdown-basic" className="bg-transparent">
                                  <DropDownIcon />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item href="#/action-3">Edit</Dropdown.Item>
                                  <Dropdown.Item href="#/action-1">Rename</Dropdown.Item>
                                  <Dropdown.Item href="#/action-2">Share</Dropdown.Item>
                                  <Dropdown.Item href="#/action-3">Delete</Dropdown.Item>

                                  {!data?.propertyImages?.[0]?.images?.[0] && (
                                    <Dropdown.Item href="#/action-2">Add Image</Dropdown.Item>
                                  )}
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            {/* <button className="btnNewPrimary sm">View More</button> */}
                          </div>
                          <div className="userArea d-flex align-items-center w-100">
                            {/* <span className="profileImg">
                            <img src={data?.image_url || defaultAvtar} />
                          </span> */}
                            <div className="d-flex justify-content-between w-100">
                              <div className="font14-medium-primary">Created by {data.userName || '-'}</div>
                              <div className="font12-medium-primary text-end">
                                {data.createdAt ? moment(data.createdAt).format('MMM DD, YYYY') : '-'}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  {!isLoading && (
                    <div className="noProperty">
                      <div className="font20Blk text-center">No Property Found!</div>
                    </div>
                  )}
                </>
              )}
            </div>
          ) : (
            <Table striped bordered hover>
              <thead>
                <tr className="font14-semibold-primary">
                  <th>Name</th>
                  <th>Created By </th>
                  <th>Last Modified</th>
                  <th>Created</th>
                  <th>Category</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {userProperty.length > 0 ? (
                  userProperty.map((template, index) => {
                    return (
                      <tr key={index}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div>
                              {template?.propertyImages?.[0]?.images?.[0] ? (
                                <div onClick={() => handleReadMore(template)}>
                                  <img
                                    className="img-fluid dashboard-table-img"
                                    alt="images"
                                    src={template?.propertyImages?.[0]?.images?.[0]}
                                  />
                                </div>
                              ) : (
                                <div onClick={() => handleReadMore(template)}>
                                  <img
                                    className="img-fluid dashboard-table-img"
                                    alt="PropertyImagePlaceholder"
                                    src={PropertyImagePlaceholder}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="ms-4 font14-semibold-primary">{template.name}</div>
                          </div>
                        </td>
                        <td className="font14-normal-primary">{template.userName}</td>
                        <td className="font14-normal-primary">{formatDate(template.updatedAt)}</td>
                        <td className="font14-normal-primary">{formatDate(template.createdAt)}</td>
                        <td>
                          <span className="me-2">
                            <DescriptionIcon />
                          </span>
                          <span className="me-2">{template.isPPPTGenerate ? <PresentationIcon /> : ''}</span>
                          <span>{template.isVideoGenerate ? <VideoIcon /> : ''}</span>
                        </td>
                        <td>
                          <span className="me-2">
                            <DeleteIcon />
                          </span>
                          <span className="me-2">
                            <EditIcon />
                          </span>
                          <span className="me-2">
                            <FavouriteIcon />
                          </span>
                          <span className="me-2">
                            <FavouriteStRockIcon />
                          </span>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <>
                    {!isLoading && (
                      <div className="noProperty">
                        <div className="font20Blk text-center">No Property Found!</div>
                      </div>
                    )}
                  </>
                )}
              </tbody>
            </Table>
          )}
        </div>
        <div className="d-flex justify-content-center mt-5 mb-5">
          {userProperty.length > 0 && userProperty.length < totalGenerations && (
            <div
              className="btnNewPrimary sm"
              onClick={() => {
                setPage(page + 1);
              }}
            >
              Load More
            </div>
          )}
        </div>
      </div>
      <Modal show={showCancelModal} backdrop="static" onHide={closeModal} className="modalCustomNew">
        <Modal.Header closeButton>Take a Tour</Modal.Header>
        <Modal.Body>Take a tour to understand the flow of the website.</Modal.Body>
        <Modal.Footer>
          <button className="btn btnBordered" onClick={closeModal}>
            Skip
          </button>
          <button className="btn btnNewPrimary" onClick={handleTour}>
            Let's take a tour
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Generation;
