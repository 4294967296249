import React, { useContext, useState } from 'react';
import { Form, Button } from 'react-bootstrap';
import { Formik, Form as FormikForm } from 'formik';
import { useNavigate } from 'react-router-dom';
import UpgradePlanModel from './upgradePlanModel';
import User from '../context/UserContext';
import { initialValues, PropertyFormValidationSchema } from '../components/Dashboard/propertyFormValidation';

const ManualPropertyForm = ({ onBack, setShowContentSelection, setShowPropertyForm, setFormData }) => {
  const { currentUser, currentWorkspace } = useContext(User);
  const navigate = useNavigate();
  const [showPlanUpgradeModal, setShowPlanUpgradeModal] = useState(false);

  // Modified submit handler with better debugging
  const handleFormSubmit = async (values) => {
    const updatedFormData = {
      ...values,
      coverImage: '',
      contactUsImageUrl: '',
      propertyImages: [
        {
          name: 'Exterior',
          length: '',
          width: '',
          sizeUnit: 'sq feet',
          noOfImages: 0,
          images: [],
          modalShow: false,
        },
        {
          name: 'Kitchen',
          length: '',
          width: '',
          sizeUnit: 'sq feet',
          noOfImages: 0,
          images: [],
          modalShow: false,
        },
      ],
    };
    // console.log('updatedFormData', updatedFormData);
    setFormData(updatedFormData);
    setShowContentSelection(true);
    setShowPropertyForm(true);
  };

  return (
    <div className="manual-property-model">
      <div className="my-4">
        <h2>Add property information</h2>
      </div>
      <Formik
        initialValues={initialValues}
        // validationSchema={PropertyFormValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleFormSubmit(values);
          setSubmitting(false);
        }}
        validateOnMount={false}
        validateOnChange={true}
        validateOnBlur={true}
      >
        {({ values, errors, touched, handleChange, handleBlur, isSubmitting, setFieldValue }) => {
          return (
            <FormikForm noValidate className="addManual-property">
              <div className="row">
                <div className="col-12 col-xl-6">
                  <Form.Group>
                    <Form.Label>Property Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="address"
                      placeholder="Property Address"
                      value={values.address}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.address && errors.address}
                      className="input-field24"
                    />
                    <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-12 col-xl-6">
                  <Form.Group>
                    <Form.Label>Property Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      name="description"
                      placeholder="Property description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.description && errors.description}
                      className="input-field24"
                    />
                    <Form.Control.Feedback type="invalid">{errors.description}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-12 col-xl-6">
                  <Form.Group>
                    <Form.Label>Property Type</Form.Label>
                    <Form.Select
                      name="type"
                      value={values.type}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.type && errors.type}
                      className="input-field28"
                    >
                      <option value="Apartment">Apartment</option>
                      <option value="House">House</option>
                      <option value="Condo">Condo</option>
                      <option value="Townhouse">Townhouse</option>
                      <option value="Villa">Villa</option>
                      <option value="Commercial">Commercial</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.type}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-12 col-xl-6">
                  <Form.Group>
                    <Form.Label>Furnished Status</Form.Label>
                    <Form.Select
                      name="furnishedStatus"
                      value={values.furnishedStatus}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.furnishedStatus && errors.furnishedStatus}
                      className="input-field28"
                    >
                      <option value="Furnished">Furnished</option>
                      <option value="Semi-Furnished">Semi-Furnished</option>
                      <option value="Unfurnished">Unfurnished</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.furnishedStatus}</Form.Control.Feedback>
                  </Form.Group>
                </div>
                <div className="col-12 col-xl-6">
                  <Form.Group>
                    <Form.Label>Property Price</Form.Label>
                    <div className="row g-0">
                      <div className="col-4 pe-3">
                        <Form.Select
                          name="priceSymbol"
                          value={values.priceSymbol}
                          onChange={handleChange}
                          className="input-field28"
                        >
                          <option value="$">$</option>
                          <option value="$CA">$CA</option>
                          <option value="$Can">$Can</option>
                          <option value="$C">$C</option>
                          <option value="£">£</option>
                          <option value="€">€</option>
                          <option value="֏">֏</option>
                          <option value="₼">₼</option>
                        </Form.Select>
                      </div>
                      <div className="col-8">
                        <Form.Control
                          type="number"
                          name="price"
                          placeholder="100000"
                          value={values.price}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.price && errors.price}
                          className="input-field210"
                        />
                        <Form.Control.Feedback type="invalid">{errors.price}</Form.Control.Feedback>
                      </div>
                    </div>
                  </Form.Group>
                </div>
                <div className="col-12 col-xl-6">
                  {values.sizeUnit === 'sq feet' ? (
                    <div className="row">
                      <div className="col-md-4">
                        <Form.Group>
                          <Form.Label>Lot Length</Form.Label>
                          <Form.Control
                            type="number"
                            name="lotLength"
                            placeholder="1200"
                            value={values.lotLength}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.lotLength && errors.lotLength}
                            className="input-field221"
                          />
                          <Form.Control.Feedback type="invalid">{errors.lotLength}</Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-md-4">
                        <Form.Group>
                          <Form.Label>Lot Width</Form.Label>
                          <Form.Control
                            type="number"
                            name="lotWidth"
                            placeholder="1200"
                            value={values.lotWidth}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.lotWidth && errors.lotWidth}
                            className="input-field221"
                          />
                          <Form.Control.Feedback type="invalid">{errors.lotWidth}</Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-md-4">
                        <Form.Label></Form.Label>
                        <Form.Select
                          name="sizeUnit"
                          value={values.sizeUnit}
                          onChange={handleChange}
                          className="input-field29"
                          style={{ marginTop: '8px' }}
                        >
                          <option value="acres">ACRES</option>
                          <option value="sq feet">SQ FT</option>
                        </Form.Select>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <Form.Label>Lot Size</Form.Label>
                      <div className="col-md-8">
                        <Form.Control
                          type="number"
                          name="sizeValue"
                          placeholder="1000"
                          value={values.sizeValue}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.sizeValue && errors.sizeValue}
                          className="input-field210"
                        />
                      </div>
                      <div className="col-md-4">
                        <Form.Select
                          name="sizeUnit"
                          value={values.sizeUnit}
                          onChange={handleChange}
                          className="input-field29"
                        >
                          <option value="acres">ACRES</option>
                          <option value="sq feet">SQ FT</option>
                        </Form.Select>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-12 col-xl-6">
                  <Form.Group>
                    <Form.Label>Property Status</Form.Label>
                    <Form.Select
                      name="status"
                      value={values.status}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.status && errors.status}
                      className="input-field28"
                    >
                      <option value="Ready to move">Ready to move</option>
                      <option value="Recently Renovated">Recently Renovated</option>
                      <option value="Under Construction">Under Construction</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.status}</Form.Control.Feedback>
                  </Form.Group>
                </div>

                <div className="col-12 col-xl-6">
                  {values.houseUnit === 'sq feet' ? (
                    <div className="row">
                      <div className="col-md-4">
                        <Form.Group>
                          <Form.Label>Sq. Length</Form.Label>
                          <Form.Control
                            type="number"
                            name="squareLength"
                            placeholder="1200"
                            value={values.squareLength}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.squareLength && errors.squareLength}
                            className="input-field221"
                          />
                          <Form.Control.Feedback type="invalid">{errors.squareLength}</Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-md-4">
                        <Form.Group>
                          <Form.Label>Sq. Width</Form.Label>
                          <Form.Control
                            type="number"
                            name="squareWidth"
                            placeholder="1200"
                            value={values.squareWidth}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={touched.squareWidth && errors.squareWidth}
                            className="input-field221"
                          />
                          <Form.Control.Feedback type="invalid">{errors.squareWidth}</Form.Control.Feedback>
                        </Form.Group>
                      </div>
                      <div className="col-md-4">
                        <Form.Label></Form.Label>
                        <Form.Select
                          name="houseUnit"
                          value={values.houseUnit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.houseUnit && errors.houseUnit}
                          className="input-field29"
                          style={{ marginTop: '8px' }}
                        >
                          <option value="" hidden disabled></option>
                          <option value="acres">ACRES</option>
                          <option value="sq feet">SQ FT</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.houseUnit}</Form.Control.Feedback>
                      </div>
                    </div>
                  ) : (
                    <div className="row">
                      <Form.Label>Square Size</Form.Label>
                      <div className="col-md-8">
                        <Form.Control
                          type="number"
                          name="houseSize"
                          placeholder="1000"
                          value={values.houseSize}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.houseSize && errors.houseSize}
                          className="input-field210"
                        />
                        <Form.Control.Feedback type="invalid">{errors.houseSize}</Form.Control.Feedback>
                      </div>
                      <div className="col-md-4">
                        <Form.Select
                          name="houseUnit"
                          value={values.houseUnit}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.houseUnit && errors.houseUnit}
                          className="input-field29"
                        >
                          <option value="" hidden disabled></option>
                          <option value="acres">ACRES</option>
                          <option value="sq feet">SQ FT</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">{errors.houseUnit}</Form.Control.Feedback>
                      </div>
                    </div>
                  )}
                </div>

                <div className="col-12 col-xl-6">
                  <Form.Label>Transaction Type</Form.Label>
                  <div className="w-100">
                    <Form.Select
                      className="input-field28"
                      type="string"
                      name="transactionType"
                      value={values.transactionType}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.transactionType && errors.transactionType}
                    >
                      <option value="" hidden disabled></option>
                      <option value="for sale">For Sale</option>
                      <option value="for rent">For Rent</option>
                    </Form.Select>
                    {touched.transactionType && errors.transactionType && (
                      <p className="text-danger text-start ms-2">{errors.transactionType}</p>
                    )}
                  </div>
                </div>

                {values.type !== 'Commercial' ? (
                  <div className="col-12 col-lg-4 col-xl-3 heading4-parent mb-3">
                    <Form.Label>Bedrooms</Form.Label>
                    <div
                      className="d-flex align-items-center border rounded w-100"
                      // style={{ border: '1px solid #E5E7EB', width: '100%' }}
                    >
                      <div className="flex-grow-1 text-center py-1">
                        <span>{values.num_bedrooms}</span>
                      </div>
                      <button
                        type="button"
                        className="decrease-button"
                        onClick={() => {
                          if (values.num_bedrooms > 0) {
                            setFieldValue('num_bedrooms', values.num_bedrooms - 1);
                          }
                        }}
                      >
                        −
                      </button>
                      <button
                        type="button"
                        onClick={() => setFieldValue('num_bedrooms', values.num_bedrooms + 1)}
                        className="increase-button"
                      >
                        +
                      </button>
                    </div>
                    {touched.num_bedrooms && errors.num_bedrooms && (
                      <p className="text-danger text-start ms-2">{errors.num_bedrooms}</p>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="col-12 col-lg-4 col-xl-3 heading4-parent mb-3">
                      <Form.Label>Meeting Rooms</Form.Label>
                      <div className="d-flex align-items-center border rounded w-100">
                        <div className="flex-grow-1 text-center py-1">
                          <span>{values.meeting_rooms}</span>
                        </div>
                        <button
                          type="button"
                          className="decrease-button"
                          onClick={() => {
                            if (values.meeting_rooms > 0) {
                              setFieldValue('meeting_rooms', values.meeting_rooms - 1);
                            }
                          }}
                        >
                          −
                        </button>
                        <button
                          type="button"
                          onClick={() => setFieldValue('meeting_rooms', values.meeting_rooms + 1)}
                          className="increase-button"
                        >
                          +
                        </button>
                      </div>
                      {touched.meeting_rooms && errors.meeting_rooms && (
                        <p className="text-danger text-start ms-2">{errors.meeting_rooms}</p>
                      )}
                    </div>

                    <div className="col-12 col-lg-4 col-xl-3 heading4-parent mb-3">
                      <Form.Label>Conference Rooms</Form.Label>
                      <div className="d-flex align-items-center border rounded w-100">
                        <div className="flex-grow-1 text-center py-1">
                          <span>{values.conference_rooms}</span>
                        </div>
                        <button
                          type="button"
                          className="decrease-button"
                          onClick={() => {
                            if (values.conference_rooms > 0) {
                              setFieldValue('conference_rooms', values.conference_rooms - 1);
                            }
                          }}
                        >
                          −
                        </button>
                        <button
                          type="button"
                          className="increase-button"
                          onClick={() => setFieldValue('conference_rooms', values.conference_rooms + 1)}
                        >
                          +
                        </button>
                      </div>
                      {touched.conference_rooms && errors.conference_rooms && (
                        <p className="text-danger text-start ms-2">{errors.conference_rooms}</p>
                      )}
                    </div>

                    <div className="col-12 col-lg-4 col-xl-3 heading4-parent mb-3">
                      <Form.Label>Cabins</Form.Label>
                      <div className="d-flex align-items-center border rounded w-100">
                        <div className="flex-grow-1 text-center py-1">
                          <span>{values.num_cabin}</span>
                        </div>
                        <button
                          type="button"
                          className="decrease-button"
                          onClick={() => {
                            if (values.num_cabin > 0) {
                              setFieldValue('num_cabin', values.num_cabin - 1);
                            }
                          }}
                        >
                          −
                        </button>
                        <button
                          type="button"
                          onClick={() => setFieldValue('num_cabin', values.num_cabin + 1)}
                          className="increase-button"
                        >
                          +
                        </button>
                      </div>
                      {touched.num_cabin && errors.num_cabin && (
                        <p className="text-danger text-start ms-2">{errors.num_cabin}</p>
                      )}
                    </div>
                  </>
                )}
                <div className="col-12 col-lg-4 col-xl-3">
                  <Form.Label>Bathrooms</Form.Label>
                  <div className="d-flex align-items-center border rounded w-100">
                    <div className="flex-grow-1 text-center py-1">
                      <span>{values.num_baths}</span>
                    </div>
                    <button
                      type="button"
                      className="decrease-button"
                      onClick={() => {
                        if (values.num_baths > 0) {
                          setFieldValue('num_baths', values.num_baths - 1);
                        }
                      }}
                    >
                      −
                    </button>
                    <button
                      type="button"
                      className="increase-button"
                      onClick={() => setFieldValue('num_baths', values.num_baths + 1)}
                    >
                      +
                    </button>
                  </div>
                  {touched.num_baths && errors.num_baths && (
                    <p className="text-danger text-start ms-2">{errors.num_baths}</p>
                  )}
                </div>

                <div className="col-12 col-lg-4 col-xl-3">
                  <Form.Label>Parking</Form.Label>
                  <div className="d-flex align-items-center border rounded w-100">
                    <div className="flex-grow-1 text-center py-1">
                      <span>{values.parking}</span>
                    </div>
                    <button
                      type="button"
                      className="decrease-button"
                      onClick={() => {
                        if (values.parking > 0) {
                          setFieldValue('parking', values.parking - 1);
                        }
                      }}
                    >
                      −
                    </button>
                    <button
                      type="button"
                      className="increase-button"
                      onClick={() => setFieldValue('parking', values.parking + 1)}
                    >
                      +
                    </button>
                  </div>
                  {touched.parking && errors.parking && <p className="text-danger text-start ms-2">{errors.parking}</p>}
                </div>

                <div className="col-12 col-lg-4 col-xl-3">
                  <Form.Label>AI Writing Creativity</Form.Label>

                  <div>
                    <div className="radio">
                      <div className="ellipse-parent2">
                        <input
                          type="radio"
                          name="AIWritingCreativity"
                          id="AIWritingCreativityNeutral"
                          checked={values.AIWritingCreativity === 'Neutral'}
                          onChange={(e) => setFieldValue('AIWritingCreativity', 'Neutral')}
                          className="custom-radio"
                        />
                        <label
                          className="email-id ms-2"
                          htmlFor="AIWritingCreativityNeutral"
                          style={{
                            fontSize: '18px',
                          }}
                        >
                          Regular
                        </label>
                      </div>
                      <div className="ellipse-parent2 ms-2">
                        <input
                          type="radio"
                          name="AIWritingCreativity"
                          id="AIWritingCreativityCreative"
                          value="Creative"
                          checked={values.AIWritingCreativity === 'Creative'}
                          onChange={(e) => setFieldValue('AIWritingCreativity', 'Creative')}
                          className="custom-radio"
                        />
                        <label
                          className="email-id ms-2"
                          style={{
                            fontSize: '18px',
                          }}
                        >
                          Creative
                        </label>
                      </div>
                    </div>
                    <div className="">
                      <div className="adjust-how-creative">
                        Adjust how creative the AI should write. Neutral is recommended for increased accuracy.
                      </div>
                    </div>
                  </div>
                </div>
                {currentUser && (!currentWorkspace?.credits || currentWorkspace.credits <= 0) && (
                  <div className=" upgradePlan">
                    <div className="font30White mb-5">Upgrade Your Plan For Description Generation</div>
                    <button
                      className="btnNewPrimary"
                      onClick={() => {
                        if (
                          currentUser &&
                          (currentWorkspace?.activePlan === 'one_time' || currentWorkspace?.activePlan === 'free')
                        ) {
                          setShowPlanUpgradeModal(true);
                        } else {
                          navigate('/pricing');
                        }
                      }}
                    >
                      Upgrade Your Plan
                    </button>
                  </div>
                )}
              </div>

              <div className="d-flex justify-content-end mt-4">
                <Button variant="secondary" className="me-3" onClick={onBack} type="button">
                  Back
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  style={{
                    backgroundColor: '#FF6B35',
                    borderColor: '#FF6B35',
                  }}
                >
                  {isSubmitting ? 'Submitting...' : 'Continue'}
                </Button>
              </div>
            </FormikForm>
          );
        }}
      </Formik>
      {showPlanUpgradeModal && (
        <UpgradePlanModel
          show={showPlanUpgradeModal}
          setShow={setShowPlanUpgradeModal}
          // price={isVideoGenerate && isPPPTGenerate ? 1500 : isVideoGenerate || isPPPTGenerate ? 1000 : 500}
        />
      )}
    </div>
  );
};

export default ManualPropertyForm;
