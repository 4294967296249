import React from 'react';
import { useNavigate } from 'react-router-dom';

const BannerHeader = ({ heading, id, isGenerationOwner = false }) => {
  const navigate = useNavigate();
  return (
    <div className="commanBanner w-100">
      <div className="container">
        <div className="row justify-content-between align-items-center commanBanner_heading">
          <div className="col font40White text-start d-flex align-items-center">
            {heading}{' '}
            {heading === 'Property Details' && isGenerationOwner ? (
              <div
                onClick={() => {
                  navigate('/add-property', {
                    state: {
                      id: id,
                      type: 'edit',
                    },
                  });
                }}
                className="d-flex align-items-center"
              >
                <button className="ms-3 fs20 btnNewPrimary sm">Edit</button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerHeader;
