import React, { useContext, useEffect, useRef, useState } from 'react';
import { NavDropdown } from 'react-bootstrap';
import User from '../../context/UserContext';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import AxiosRequest from '../../utils/AxiosRequest';
import toast from 'react-hot-toast';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BellIconBig } from '../../shared/Icons';
import { NotificationIcon } from '../../shared/NewIcon';

const Notification = () => {
  const {
    currentUser,
    notifications,
    hasMore,
    containerRef,
    loadmore,
    handleScroll,
    notificationCount,
    setNotifications,
    setPage,
    setNotificationCount,
    setHasMore,
    getNotifications,
    page,
  } = useContext(User);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleUpdateNotification = async (notificationId) => {
    try {
      const id = [notificationId];
      await AxiosRequest.post('/notification/updateNotification', { id: id });
      const updatedNotifications = notifications.map((notification) => {
        if (notification._id === notificationId && !notification.isRead) {
          notification.isRead = true;
          setNotificationCount((prevCount) => prevCount - 1);
        }
        return notification;
      });
      setNotifications(updatedNotifications);
      getNotifications();
      setPage(1);
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      console.log(err);
    }
  };

  const handleMarkAllAsRead = async () => {
    try {
      const id = [];
      for (let i = 0; i < notifications.length; i++) {
        if (notifications[i].isRead === false) {
          id.push(notifications[i]._id);
        }
      }
      await AxiosRequest.post('/notification/updateNotification', { id: id });
      const updatedNotifications = notifications.filter((notification) => notification.isRead);
      setNotifications(updatedNotifications);
      getNotifications();
      setNotificationCount(0);
      setPage(1);
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      console.log(err);
    }
  };

  return (
    <div
      className="icon-bg"
      style={{
        border: notificationCount > 0 ? '2px solid #FF6B3580' : 'none',
      }}
    >
      <div className="position-relative" ref={dropdownRef}>
        {currentUser !== null && currentUser !== undefined && (
          <div>
            <div
              className="notification-icon-wrapper"
              onClick={() => {
                setIsOpen(!isOpen);
                if (page === 1) {
                  getNotifications();
                  setHasMore(true);
                } else {
                  setHasMore(true);
                  setPage(1);
                }
              }}
            >
              {notificationCount > 0 && <div className="notificationNum">{notificationCount}</div>}
              <NotificationIcon />
            </div>

            {isOpen && (
              <div className="position-absolute notification-dropdown">
                <div className="w-100">
                  <div className="heading w-100 text-center">Notifications</div>
                  {notificationCount > 0 && (
                    <div
                      onClick={() => {
                        setHasMore(true);
                        handleMarkAllAsRead();
                      }}
                      className="text-end m-2"
                      style={{ width: '95%', cursor: 'pointer' }}
                    >
                      Mark all as read
                    </div>
                  )}
                  {notifications?.length === 0 && (
                    <div className="text-center p-5">
                      <BellIconBig />
                      <p style={{ whiteSpace: 'nowrap' }} className="mt-3">
                        No Notification Yet
                      </p>
                    </div>
                  )}
                </div>

                {notifications?.length > 0 && (
                  <div ref={containerRef} className="notifications-container" onScroll={handleScroll}>
                    <InfiniteScroll dataLength={notifications.length} next={loadmore} hasMore={hasMore}>
                      {notifications.map((data, index) => (
                        <div
                          key={index}
                          className="notification-item mb-1 py-2"
                          style={{
                            backgroundColor: data.isRead === false ? '#ededed' : 'white',
                            whiteSpace: 'wrap',
                          }}
                          onClick={() => {
                            if (data.isRead === false) {
                              setHasMore(true);
                              handleUpdateNotification(data._id);
                            }
                            if (data?.property) {
                              navigate(`/property-details/${data.property}`);
                            }
                          }}
                        >
                          <div className="title">{data.title}</div>
                          <div className="date">{moment(data.createdAt).format('DD MMM YYYY, hh:mm:s')}</div>
                        </div>
                      ))}
                    </InfiniteScroll>
                  </div>
                )}
              </div>
            )}
          </div>
        )}

        <style>
          {`
          .notification-icon-wrapper {
            cursor: pointer;
            position: relative;
            padding: 8px;
          }
          .notification-dropdown {
            right: 0;
            top: 100%;
            background: white;
            border-radius: 4px;
            box-shadow: 0 2px 10px rgba(0,0,0,0.1);
            min-width: 260px;
            z-index: 1000;
          }
          .notifications-container {
            max-height: 500px;
            overflow-y: auto;
          }
          .notification-item {
            padding: 10px 15px;
            cursor: pointer;
            border-bottom: 1px solid #eee;
          }
          .notification-item:hover {
            background-color: #f8f9fa !important;
          }
          .notificationNum {
            position: absolute;
            left: 17px;
            top: 17px;
            color:#FF6B35;
            border-radius: 50%;
            min-width: 18px;
            height: 18px;
            font-size: 10px;
        font-weight:600;
          }
          .heading {
            padding: 15px;
            border-bottom: 1px solid #eee;
            font-weight: 500;
          }
          .title {
            font-size: 14px;
            margin-bottom: 5px;
            text-align: start;
          }
          .date {
            font-size: 12px;
            color: #6c757d;
            text-align: start;
          }
        `}
        </style>
      </div>
    </div>
  );
};

export default Notification;
