// App.jsx
import React, { useState, useEffect, useContext } from 'react';
import Logo from '../../assets/images/LogoNew.png';
import { menuItems } from '../data';
import { useNavigate } from 'react-router-dom';
import { getInitials, handleLogout } from '../../utils/common';
import User from '../../context/UserContext';
import CreateButton from '../../components/Dashboard/CreateButton';
import { MenuClose, MenuOpen } from '../../shared/NewIcon';

const Sidebar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { setCurrentUser, currentUser } = useContext(User);
  const [activeItem, setActiveItem] = useState('Home');
  const [expandedItem, setExpandedItem] = useState('');
  const navigate = useNavigate();

  const handleLogoutClick = () => {
    handleLogout(navigate, setCurrentUser);
  };

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      setIsCollapsed(width <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleItemClick = (title) => {
    setActiveItem(title);
    if (menuItems.find((item) => item.title === title)?.subItems) {
      setExpandedItem(expandedItem === title ? '' : title);
    }
  };

  const getActiveContent = () => {
    const activeMenuItem = menuItems.find((item) => item.title === activeItem);
    return typeof activeMenuItem?.content === 'function'
      ? activeMenuItem.content({ setActiveItem })
      : activeMenuItem?.content || (
          <div className="content-section">
            <h2>{activeItem}</h2>
            <p>Content for {activeItem} goes here...</p>
          </div>
        );
  };

  return (
    <div className="d-flex custom-sidebar">
      <div className={`sidebar ${isCollapsed ? 'collapsed' : ''}`}>
        <div className="sidebar-header">
          <div className="logo-container">
            {!isCollapsed && (
              <a href={`https://restatecopilot.io/?isVerified=${currentUser?.isVerified}`} className="logo-text">
                <img src={Logo} alt="logo" className="img-fluid" />
              </a>
            )}
          </div>
          <button className="toggle-btn" onClick={() => setIsCollapsed(!isCollapsed)}>
            {isCollapsed ? <MenuClose /> : <MenuOpen />}
          </button>
        </div>

        <div className="menu-items">
          <div className="mx-3">
            <div className="user">
              <div className="profile-box">{getInitials(currentUser?.name)}</div>
              <div>
                <div className="profile-title">
                  {currentUser &&
                    (currentUser.name.length > 16 ? `${currentUser.name.slice(0, 16)}...` : currentUser.name)}
                </div>
                <p className="profile-email">
                  {currentUser &&
                    (currentUser.email.length > 25 ? `${currentUser.email.slice(0, 25)}...` : currentUser.email)}
                </p>
              </div>
            </div>
          </div>
          <CreateButton isCollapsed={isCollapsed} />
          {menuItems.map((item) => (
            <div key={item.title} className="mx-3 my-2">
              <div
                className={`menu-item ${activeItem === item.title ? 'active' : ''}`}
                onClick={() => {
                  if (item.title === 'Logout') {
                    handleLogoutClick();
                  } else {
                    handleItemClick(item.title);
                  }
                }}
              >
                <div className="icon-container">{item.icon}</div>
                {!isCollapsed && (
                  <span className="menu-title">
                    {item.title}
                    {item.badge && <span className="badge">{item.badge}</span>}
                  </span>
                )}
              </div>
              {!isCollapsed && item.subItems && expandedItem === item.title && (
                <div className="sub-items">
                  {item.subItems.map((subItem) => (
                    <div key={subItem} className="sub-item">
                      {subItem}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      <div className="main-content">{getActiveContent()}</div>
    </div>
  );
};

export default Sidebar;
