import React, { useState, useContext, useEffect } from 'react';
import { Form, Button, Container, Spinner } from 'react-bootstrap';
import { Field, Formik } from 'formik';
import AudioPlayer from './AudioPlayer';
import { UploadPropertyIcon } from '../../shared/Icons';
import { musicArray } from '../../utils';
import User from '../../context/UserContext';
import AxiosRequest from '../../utils/AxiosRequest';
import { useNavigate } from 'react-router-dom';
import VideoTemplateSelection from '../../components/VideoTemplateSelection';
import UpgradePlanModel from '../../models/upgradePlanModel';
import { Description, FullVideo, Presentation, StartIcon } from '../../shared/NewIcon';
import ImageUploadComponent from '../../components/Dashboard/ImageUploadComponent';
import { uploadImageWithSignUrl } from '../../utils/UploadImageWiths3';
import axios from 'axios';
import pptMainImg from '../../assets/images/pptMainImg.png';
import html2canvas from 'html2canvas';
import { extractShortName } from '../../utils/common';

const ContentSelectionForm = ({ formData, onClose }) => {
  const navigate = useNavigate();
  const { currentUser, currentWorkspace } = useContext(User);
  const [selectedVoice, setSelectedVoice] = useState({});
  const [isVoicePlaying, setIsVoicePlaying] = useState(false);
  const [voices, setVoices] = useState([]);
  const [isBackgroundMusic, setIsBackgroundMusic] = useState(false);
  const [isVideoGeneration, setIsVideoGeneration] = useState(false);
  const [selectedMusic, setSelectedMusic] = useState({ name: '', url: '' });
  const [currentStep, setCurrentStep] = useState(1);
  const [videoTemplates, setVideoTemplates] = useState([]);
  const [showPlanUpgradeModal, setShowPlanUpgradeModal] = useState(false);
  const [contactUsImage, setContactUsImage] = useState();

  const useId = JSON.parse(sessionStorage.getItem('currentUser'))._id;
  const hasVideoGenerateProperty = formData && 'isVideoGenerate' in formData;

  const initialValues = {
    ...formData,
    isVideo: false,
    presentation: false,
    videoTemplate: null,
  };

  useEffect(() => {
    if (isVideoGeneration && voices.length === 0) {
      getElevenLabsVoices();
    }
  }, [isVideoGeneration, voices]);

  useEffect(() => {
    if (currentUser && hasVideoGenerateProperty) {
      getContactUsImage();
    }
  }, [currentUser]);
  // Keep all your existing voice and music functions unchanged
  const getElevenLabsVoices = async () => {
    try {
      const response = await AxiosRequest.get('/property/eleven-labs-voices');
      setVoices(response.data.data);
      const defaultVoice = response.data.data.find((voice) => voice.name === 'Bill');
      setSelectedVoice(defaultVoice || {});
    } catch (err) {
      console.log(err);
    }
  };

  const handleVoiceChange = (event) => {
    setIsVoicePlaying(false);
    const selectedVoiceName = event.target.value;
    const selectedVoiceObject = voices.find((voice) => voice.name === selectedVoiceName) || {};
    setSelectedVoice(selectedVoiceObject);
  };

  const handleMusicChange = (event) => {
    setIsBackgroundMusic(false);
    if (event.target.value !== 'Custom Music') {
      const selectedMusicName = event.target.value;
      const selectedMusicObject = musicArray.find((music) => music.name === selectedMusicName) || {};
      setSelectedMusic(selectedMusicObject);
    } else {
      setSelectedMusic({ name: event.target.value, url: '' });
    }
  };

  const handleCustomMusicUpload = (event) => {
    const file = event.target.files[0];
    const ext = file.type.split('/');
    if (ext[1] === 'mp3' || ext[1] === 'mpeg') {
      const url = URL.createObjectURL(file);
      const customMusicItem = { name: 'Custom Music', url };
      setSelectedMusic(customMusicItem);
    }
  };

  const handleNext = async () => {
    if (currentStep === 1) {
      try {
        const response = await AxiosRequest.get('/property/video-template');
        setVideoTemplates(response?.data?.data);
        setCurrentStep(2);
      } catch (error) {
        console.error('Error fetching video templates:', error);
      }
    }
  };

  const getContactUsImage = async () => {
    const hiddenDiv = document.createElement('div');
    hiddenDiv.style.position = 'absolute';
    hiddenDiv.style.left = '-9999px';
    document.body.appendChild(hiddenDiv);
    let name = formData.address;
    const commaCount = (formData.address.match(/,/g) || []).length;
    const spacecount = (formData.address.match(/ /g) || []).length;
    let regexPattern;
    if (commaCount > 2) {
      regexPattern = /([^,\s]+(?: [^,\s]+)*)[^,]*,[^,]*,/;
    } else if (spacecount > 2) {
      regexPattern = /([^,\s]+(?: [^,\s]+)*)(?:\s[^,\s]+){0,2}\s/;
    }
    if (regexPattern) {
      const matches = regexPattern.exec(formData.address);
      if (matches && matches.length > 1) {
        name = matches[1].trim();
      } else {
        name = formData.address;
      }
    } else {
      name = formData.address;
    }
    let profilePhoto;
    if (currentUser && currentUser.photo && currentUser.photo.startsWith('http')) {
      await axios.get(currentUser.photo, { responseType: 'blob' }).then((response) => {
        if (response.status === 200) {
          const blobURL = URL.createObjectURL(response.data);
          profilePhoto = blobURL;
        } else {
          throw new Error('Failed to fetch image');
        }
      });
    }
    // Render the HTML content inside the hidden div
    hiddenDiv.innerHTML = `
        <div className="pptSlide" style="padding: 0px; margin-bottom: 0px;">
          <div className="row align-content-center justify-content-between">
            <div className="col-12 col-lg-7 p-50 d-flex align-items-center mb-5">
              <div className="text-start">
              <div>
              ${
                profilePhoto
                  ? `<div className="d-flex justify-content-center align-items-center w-100 mb-5">
                      <img
                        src="${profilePhoto}"
                        alt=""
                        style="max-width: 200px; max-height: 200px; aspect-ratio: 4/3"
                      />
                    </div>`
                  : ''
              }
              </div>
                <div className="font20BlackPPT">${name}</div>
                <div className="font30BoldPPTRed">Name: ${currentUser && currentUser.name}</div>
                ${
                  currentUser && currentUser.mobileNumber !== undefined
                    ? `<div className="font20BlackPPT">Mobile No. : ${currentUser && currentUser.mobileNumber}</div>`
                    : ''
                }
                <div className="font20BlackPPT">Email ID : ${currentUser && currentUser.email}</div>
              </div>
            </div>
            <div className="col-12 col-lg-5">
              <img src="${pptMainImg}" alt="" className="pptMainImg" />
            </div>
          </div>
        </div>
      `;

    html2canvas(hiddenDiv).then((canvas) => {
      const dataURL = canvas.toDataURL('image/png');
      setContactUsImage(dataURL);
    });
  };

  const dataURLToBlob = async (dataURL) => {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onload = function () {
        resolve(xhr.response);
      };
      xhr.onerror = function () {
        reject(new Error('Failed to convert data URL to Blob'));
      };
      xhr.responseType = 'blob';
      xhr.open('GET', dataURL);
      xhr.send();
    });
  };

  const handleSubmitForm = async (values) => {
    try {
      let name = extractShortName(values.address);

      let updatedValues = { ...values };
      if (hasVideoGenerateProperty) {
        let coverImageUrl;
        let propertyImages = [];

        if (contactUsImage) {
          const blob = await dataURLToBlob(contactUsImage);
          const s3Url = await uploadImageWithSignUrl(blob);
          updatedValues.contactUsImageUrl = s3Url;
        }
        // Handle cover image upload if exists
        if (values.coverImage) {
          const blob = await dataURLToBlob(values.coverImage);
          coverImageUrl = await uploadImageWithSignUrl(blob);
          updatedValues.coverImage = coverImageUrl;
        }

        if (values.propertyImages && values.propertyImages.length > 0) {
          // Deep clone the propertyImages array to avoid mutating the original
          propertyImages = JSON.parse(JSON.stringify(values.propertyImages));

          for (let i = 0; i < propertyImages.length; i++) {
            if (propertyImages[i].images && propertyImages[i].images.length > 0) {
              for (let j = 0; j < propertyImages[i].images.length; j++) {
                const currentImage = propertyImages[i].images[j];

                if (currentImage && currentImage.image_url) {
                  try {
                    const blob = await dataURLToBlob(currentImage.image_url);
                    // Upload image and get signed URL
                    const url = await uploadImageWithSignUrl(blob);
                    propertyImages[i].images[j] = url;
                  } catch (error) {
                    console.error('Error processing image:', error);
                    // Handle the error appropriately
                  }
                }
              }
            }
          }

          // Handle custom names in property images
          propertyImages = propertyImages.map((field) => {
            if (field.customName) {
              const { customName, ...rest } = field;
              return { ...rest, name: customName };
            }
            return field;
          });

          updatedValues.propertyImages = propertyImages;
        }
      }

      const payload = {
        ...updatedValues,
        status: 'Ready to move',
        voice_id: values?.isVideo ? selectedVoice.voice_id : null,
        music_url: values?.isVideo ? selectedMusic.url : null,
        workSpace: currentWorkspace ? currentWorkspace._id : null,
        videoStatus: 'pending',
        user: useId,
        videoTemplate: values?.isVideo ? values?.videoTemplate?._id : null,
      };
      if (hasVideoGenerateProperty) {
        const { videoStatus, videoTemplate, isVideo, presentation, ...restPayload } = {
          ...payload,
          size: {
            value: values.sizeValue,
            unit: values.sizeUnit,
          },
          name,
        };
        const newpayload = {
          ...restPayload,
          isVideoGenerate: isVideo,
          isPPPTGenerate: presentation,
        };
        // console.log('🚀 ~ handleSubmitForm ~ newpayload:', newpayload);
        const response = await AxiosRequest.post('/property', newpayload);
        if (response?.status === 200) {
          onClose();
          navigate('/dashboard?isgeneration=true');
        }
      } else {
        const response = await AxiosRequest.post('/property/url', payload);
        if (response?.status === 200) {
          onClose();
          navigate('/dashboard?isgeneration=true');
        }
      }
      // console.log('payload', payload);
    } catch (error) {
      console.error('Error:', error);
    }
  };
  return (
    <Container>
      <Formik initialValues={initialValues} onSubmit={handleSubmitForm}>
        {(formikProps) => {
          const { values, handleSubmit, isSubmitting, setFieldValue } = formikProps;
          return (
            <Form onSubmit={handleSubmit}>
              {currentStep === 1 && (
                <div className="row justify-content-center">
                  <div closeButton className="model-header">
                    <div className="w-100 text-center model-title">
                      What do you want us to <span style={{ color: '#FF6B35' }}>create</span>?
                    </div>
                  </div>
                  <div className="d-flex col-auto justify-content-center w-100 mb-4" style={{ gap: '50px' }}>
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <label className={`content-option-card ${values.presentation ? 'selected' : ''}`}>
                        <Field
                          type="checkbox"
                          name="presentation"
                          className="d-none"
                          onChange={(e) => setFieldValue('presentation', e.target.checked)}
                        />
                        <div>
                          <Presentation />
                        </div>
                      </label>
                      <div className="text-center">
                        <p className="option-title">Presentation</p>
                        <p className="option-dimensions">1920 × 1080 px</p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <label className={`content-option-card ${values.isVideo ? 'selected' : ''}`}>
                        <Field
                          type="checkbox"
                          name="isVideo"
                          className="d-none"
                          onChange={(e) => {
                            setFieldValue('isVideo', e.target.checked);
                            if (e.target.checked) {
                              setIsVideoGeneration(true);
                              if (hasVideoGenerateProperty && hasVideoGenerateProperty !== undefined) {
                                formData.isVideoGenerate = true;
                              }
                            }
                          }}
                        />
                        <div className="content-option-image">
                          <FullVideo />
                        </div>
                      </label>
                      <div className="text-center">
                        <p className="option-title">Video</p>
                        <p className="option-dimensions">1920 × 1080 px</p>
                      </div>
                    </div>

                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <label className="content-option-card selected">
                        <Field type="checkbox" name="descriptions" checked={true} readOnly className="d-none" />
                        <div className="content-option-image">
                          <Description />
                        </div>
                      </label>
                      <div className="text-center">
                        <p className="option-title">Descriptions</p>
                        <p className="option-dimensions">Add content</p>
                      </div>
                    </div>
                  </div>

                  {values.isVideo && (
                    <div className="row">
                      {/* Keep your existing voice and music selection JSX */}
                      <div className="heading4-parent col-12 col-md-6 mb-4">
                        <div style={{ fontSize: '14px', color: '#6C6C6C', fontWeight: '500', marginBottom: '10px' }}>
                          Select Voice
                        </div>
                        <div className="d-flex w-100">
                          <div className="w-100">
                            <div>
                              <select className="input-field20" value={selectedVoice.name} onChange={handleVoiceChange}>
                                {voices.map((voice) => (
                                  <option key={voice.name} value={voice.name}>
                                    {voice.name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center ms-3">
                            {selectedVoice && (
                              <AudioPlayer
                                audioUrl={selectedVoice.preview_url}
                                isPlaying={isVoicePlaying}
                                setIsPlaying={setIsVoicePlaying}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="heading4-parent col-12 col-md-6 mb-4">
                        <div style={{ fontSize: '14px', color: '#6C6C6C', fontWeight: '500', marginBottom: '10px' }}>
                          Background Music
                        </div>
                        <div className="d-flex w-100">
                          <div className="w-100" style={{ maxWidth: '287px' }}>
                            <select className="input-field20" value={selectedMusic.name} onChange={handleMusicChange}>
                              {/* <option value="" selected>
                                Select
                              </option> */}
                              {musicArray.map((music) => (
                                <option key={music.name} value={music.name}>
                                  {music.name}
                                </option>
                              ))}
                              <option value="Custom Music">Custom Music</option>
                            </select>
                          </div>
                          <div className="d-flex justify-content-between align-items-center ms-3">
                            {selectedMusic && selectedMusic.name === 'Custom Music' && selectedMusic.url === '' && (
                              <>
                                <input
                                  type="file"
                                  accept="audio/*"
                                  onChange={handleCustomMusicUpload}
                                  className="customAudioInput"
                                />
                                <div className="">
                                  <UploadPropertyIcon />
                                </div>
                              </>
                            )}
                            {selectedMusic && selectedMusic.url && (
                              <AudioPlayer
                                audioUrl={selectedMusic.url}
                                isPlaying={isBackgroundMusic}
                                setIsPlaying={setIsBackgroundMusic}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {values?.isVideo && formData?.isVideoGenerate && <ImageUploadComponent formik={formikProps} />}
                  <div className="d-flex justify-content-center w-100">
                    <Button
                      type="button"
                      className="btnNewPrimary sm my-4"
                      disabled={isSubmitting}
                      onClick={() => {
                        if (values.isVideo) {
                          handleNext();
                        } else {
                          handleSubmit();
                        }
                      }}
                    >
                      {isSubmitting ? (
                        <div className="d-flex align-items-center">
                          <Spinner size="sm" className="me-2" />
                          <span>Processing...</span>
                        </div>
                      ) : values.isVideo ? (
                        'Continue'
                      ) : (
                        <>
                          Go
                          <StartIcon />
                        </>
                      )}
                    </Button>
                  </div>
                </div>
              )}

              {currentUser && currentWorkspace?.credits <= 0 && currentUser.credits <= 0 && (
                <div className="upgradePlan">
                  <div className="font30White mb-5">Upgrade Your Plan For Description Generation</div>
                  <button
                    className="btnNewPrimary"
                    onClick={() => {
                      if (
                        currentUser &&
                        (currentWorkspace?.activePlan === 'one_time' || currentWorkspace?.activePlan === 'free')
                      ) {
                        setShowPlanUpgradeModal(true);
                      } else {
                        navigate('/pricing');
                      }
                    }}
                  >
                    Upgrade Your Plan
                  </button>
                </div>
              )}

              {currentStep === 2 && (
                <VideoTemplateSelection
                  videoTemplates={videoTemplates}
                  isSubmitting={isSubmitting}
                  onSubmit={(template) => {
                    setFieldValue('videoTemplate', template);
                    handleSubmit();
                  }}
                  onBack={() => setCurrentStep(1)}
                />
              )}

              {showPlanUpgradeModal && (
                <UpgradePlanModel
                  show={showPlanUpgradeModal}
                  setShow={setShowPlanUpgradeModal}
                  price={
                    values.isVideo && values.presentation ? 1500 : values.isVideo || values.presentation ? 1000 : 500
                  }
                />
              )}
            </Form>
          );
        }}
      </Formik>
    </Container>
  );
};

export default ContentSelectionForm;