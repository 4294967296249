import React, { useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { UploadPropertyIcon, CancleProperty, AddPropertyIcon } from '../../shared/Icons';
import EditImageModal from '../../app/PropertyPage/EditImageModal';
import VideoDetails from '../../app/PropertyPage/VideoDetails';
// import EditImageModal

const ImageUploadComponent = ({ formik }) => {
  const coverImageRef = useRef(null);
  const [modalShow, setModalShow] = useState(false);
  const [isShowCropImageModal, setIsShowCropImageModal] = useState({ show: false, col: 0 });
  const [ImagesToCrop, setImagesToCrop] = useState({ image_Url: null, image_file: null, image_name: null });
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [id, setId] = useState();

  const handlecoverImageChange = (e) => {
    const file = e.target.files[0];
    const ext = file.type.split('/');
    if (ext[1] === 'png' || ext[1] === 'jpg' || ext[1] === 'jpeg' || ext[1] === 'webp') {
      formik.setFieldValue('coverImage', URL.createObjectURL(e.target.files[0]));
    } else {
      formik.setFieldError('coverImage', 'Only image required');
    }
    coverImageRef.current.value = '';
  };

  const onSelectFile = (file, columnIndex) => {
    if (file === undefined) {
      formik.setFieldError(
        `propertyImages[${columnIndex}].images[${formik.values.propertyImages[columnIndex].length}]`,
        'Only one image is allowed'
      );
    } else if (file) {
      const filee = file;
      const ext = filee.type.split('/');
      if (ext[1] === 'png' || ext[1] === 'jpg' || ext[1] === 'jpeg' || ext[1] === 'webp') {
        const newImage = {
          image_name: filee.name,
          image_file: filee,
          image_url: URL.createObjectURL(filee),
        };
        // Update the specific image in the propertyImages array
        formik.setFieldValue(
          `propertyImages[${columnIndex}].images[${formik.values.propertyImages[columnIndex].images.length}]`,
          newImage
        );
        // Update the noOfImages count for the current column
        const updatedImages = formik.values.propertyImages[columnIndex].images;
        formik.setFieldValue(`propertyImages[${columnIndex}].noOfImages`, updatedImages.length);
        return;
      } else {
        formik.setFieldError(
          `propertyImages[${columnIndex}].images[${formik.values.propertyImages[columnIndex].images.length}]`,
          'Only image required'
        );
        return;
      }
    }
  };
  const handleFileChange = (e, index) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setIsShowCropImageModal({ show: true, col: index });
      setImagesToCrop({
        image_Url: URL.createObjectURL(file),
        image_file: file,
        image_name: file.name,
      });
    }
  };
  const handlePropertyHeightChange = (e, index) => {
    formik.setFieldValue(`propertyImages[${index}].length`, e.target.value);
  };

  const handlePropertyWidthChange = (e, index) => {
    formik.setFieldValue(`propertyImages[${index}].width`, e.target.value);
  };

  const handlePropertySizeUnitChange = (e, index) => {
    formik.setFieldValue(`propertyImages[${index}].sizeUnit`, e.target.value);
  };

  const handleRemovePropertyImage = (index) => {
    const updatedImages = formik.values.propertyImages.filter((_, i) => i !== index);
    formik.setFieldValue('propertyImages', updatedImages);
  };

  const onRemoveImage = (columnIndex, imageIndex) => {
    const currentImages = formik.values.propertyImages[columnIndex].images;

    if (imageIndex >= 0 && imageIndex < currentImages.length) {
      const updatedImages = [...currentImages.slice(0, imageIndex), ...currentImages.slice(imageIndex + 1)];

      formik.setFieldValue(`propertyImages[${columnIndex}].images`, updatedImages);

      // Recalculate the number of non-empty images
      const nonEmptyImages = updatedImages.filter((image) => image !== null);
      formik.setFieldValue(`propertyImages[${columnIndex}].noOfImages`, nonEmptyImages.length);
    }
  };

  const addNewProperty = () => {
    const newProperty = {
      images: [],
      name: '',
      length: '',
      width: '',
      sizeUnit: '',
      customName: '',
    };
    formik.setFieldValue('propertyImages', [...formik.values.propertyImages, newProperty]);
  };

  return (
    <div className="frame-parent3 w-100 mb-2 d-block">
      <div className="row">
        <div className="col-xl-4 col-lg-6 mb-3">
          <div className="image-upload-label text-start mb-2">Upload cover image</div>
          <div className="input-field2-parent9">
            <input
              name="coverImage"
              type="file"
              accept="image/png, image/jpeg, image/jpg, image/webp"
              ref={coverImageRef}
              onChange={handlecoverImageChange}
            />
            {formik?.values?.coverImage ? (
              <img
                src={formik.values.coverImage}
                alt="cover-image"
                className="img-fluid"
                style={{ maxHeight: '230px' }}
              />
            ) : (
              <>
                <div className="input-field218 manual-image-upload">
                  <div className="drop">
                    Drop your files here or <br />
                    <span className="browse">browse</span>
                  </div>
                </div>
                <div></div>
                <div className="upload-1-icon">
                  <UploadPropertyIcon />
                </div>
              </>
            )}
          </div>
          {formik.errors.coverImage && <p className="text-danger text-start ms-2">{formik.errors.coverImage}</p>}
        </div>

        <EditImageModal
          isShowCropImageModal={isShowCropImageModal}
          setIsShowCropImageModal={setIsShowCropImageModal}
          ImagesToCrop={ImagesToCrop}
          setImagesToCrop={setImagesToCrop}
          onSelectFile={onSelectFile}
          handleFileChange={handleFileChange}
          selectedItemIndex={selectedItemIndex}
          setModalShow={setModalShow}
        />

        {/* {formik?.values?.propertyImages?.map((data, index) => (
          <div
            className={`col-lg-4 col-md-6 col-sm-12 mb-3 ${formik.values.propertyImages.length > 1 ? 'mb-3' : ''}`}
            key={`${index}-${data.name}`}
            style={{ position: 'relative' }}
          ></div>
        ))} */}
        {formik?.values?.propertyImages?.map((data, index) => {
          return (
            <div
              className={`col-xl-4 col-lg-6 col-sm-12 mb-3 ${formik.values.propertyImages.length > 1 ? 'mb-3' : ''}`}
              key={`${index}-${data.name}`}
              style={{ position: 'relative' }}
            >
              <div className="image-upload-label text-start mb-2">Upload image</div>
              <input
                onChange={(e) => handleFileChange(e, index)}
                style={{ display: 'none' }}
                type="file"
                id={`choose-${index}`}
                accept="image/png, image/jpeg, image/jpg, image/webp"
              />
              <VideoDetails
                show={modalShow && selectedItemIndex !== null && selectedItemIndex === index}
                setModalShow={setModalShow}
                onHide={() => {
                  setModalShow(false);
                  setSelectedItemIndex(null);
                }}
                onSelectFile={onSelectFile}
                onRemoveImage={onRemoveImage}
                columnIndex={index}
                data={data}
                setIsShowCropImageModal={setIsShowCropImageModal}
                // isShowCropImageModal={isShowCropImageModal}
                id={id}
              />
              <div className="frame-parent4 h-100">
                <div className="w-100">
                  <Row>
                    <div className="input-field2-parent10 ">
                      <>
                        {data.images.length > 0 ? (
                          <>
                            {data.images.slice(0, 2).map((file, childIndex) => {
                              return (
                                <div
                                  onClick={() => {
                                    setModalShow(true);
                                    setSelectedItemIndex(index);
                                  }}
                                  className="input-field219 position-relative"
                                  style={{
                                    marginRight: '5px',
                                    width: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '80px',
                                  }}
                                >
                                  <img
                                    style={{
                                      width: '100%',
                                      height: '100%',
                                    }}
                                    alt=""
                                    src={id ? (file?.image_url ? file.image_url : file) : file?.image_url}
                                    className="cursor-pointer rounded"
                                  />
                                  {childIndex === 1 && data.images.length - 2 && data.images.length > 2 ? (
                                    <div className="position-absolute imageCount cursor-pointer">
                                      {data.images.length - 2}+
                                    </div>
                                  ) : (
                                    ''
                                  )}
                                </div>
                              );
                            })}
                            <>
                              <div
                                className="input-field219 cursor-pointer manual-image-upload"
                                onClick={() => {
                                  setSelectedItemIndex(index);
                                }}
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '80px',
                                }}
                              >
                                <label
                                  htmlFor={`choose-${index}`}
                                  onClick={() => {
                                    setIsShowCropImageModal({
                                      show: false,
                                      col: index,
                                    });
                                    setSelectedItemIndex(index);
                                  }}
                                >
                                  <div className="cursor-pointer">
                                    <UploadPropertyIcon />
                                  </div>
                                  <div className="drop">
                                    Drop your files here or <br />
                                    <span className="browse">browse</span>
                                  </div>
                                </label>
                              </div>
                            </>
                          </>
                        ) : (
                          <div
                            className="input-field219 manual-image-upload"
                            style={{
                              width: '100%',
                              height: '80px',
                              display: 'flex',
                              flexDirection: 'column',
                            }}
                          >
                            <label htmlFor={`choose-${index}`}>
                              <div
                                className="cursor-pointer"
                                onClick={() => {
                                  setIsShowCropImageModal({
                                    show: false,
                                    col: index,
                                  });
                                  setSelectedItemIndex(index);
                                }}
                              >
                                <UploadPropertyIcon />
                              </div>
                              <div className="drop">
                                Drop your files here or <br />
                                <span className="browse">browse</span>
                              </div>
                            </label>
                          </div>
                        )}
                      </>
                    </div>
                    {formik.errors.propertyImages &&
                      formik.errors.propertyImages[index] &&
                      formik.errors.propertyImages[index].images && (
                        <div className="text-danger text-start">{formik.errors.propertyImages[index].images}</div>
                      )}
                  </Row>
                </div>

                <div className="frame-parent5-add">
                  <div className="heading4-parent col-12 w-100">
                    <div className="image-upload-label text-start my-2">Select room</div>
                    <div className="input-field2-parent1">
                      <select
                        className="input-field220"
                        name={`propertyImages.${index}.name`}
                        value={data.name}
                        onChange={(e) => {
                          formik.setFieldValue(`propertyImages[${index}].name`, e.target.value);
                        }}
                      >
                        <option value={''} hidden disabled></option>
                        {formik.values.type !== 'Commercial' ? (
                          <>
                            <option value="Exterior">Exterior</option>
                            <option value="Garden">Garden</option>
                            <option value="Garage">Garage</option>
                            <option value="Dining-room">Dining room</option>
                            <option value="Home-office">Home Office</option>
                            <option value="Living-room">Living Room</option>
                            <option value="Kitchen">Kitchen</option>
                            <option value="Store-room">Store Room</option>
                            <option value="Guest-room">Guest Room</option>
                            <option value="Bedroom">Bedroom</option>
                            <option value="Balcony">Balcony</option>
                            <option value="Custom">Custom</option>
                          </>
                        ) : (
                          <>
                            <option value="Meeting-rooms">Meeting Rooms</option>
                            <option value="Conference-rooms">Conference Rooms</option>
                            <option value="Cabins">Cabins</option>
                            <option value="Parkings">Parkings</option>
                            <option value="Bathrooms">Bathrooms</option>
                            <option value="Custom">Custom</option>
                          </>
                        )}
                      </select>
                    </div>

                    {formik.values.propertyImages[index].name === 'Custom' ? (
                      <input
                        className="input-field25 mt-2"
                        placeholder="Add Custom Type"
                        type="string"
                        id="customValue"
                        name="customValue"
                        value={formik.values.customValue}
                        onChange={(e) => {
                          formik.setFieldValue(`propertyImages[${index}].customName`, e.target.value);
                        }}
                      />
                    ) : null}

                    {formik.errors.propertyImages &&
                      formik.errors.propertyImages[index] &&
                      formik.errors.propertyImages[index].name && (
                        <div className="text-danger text-start">{formik.errors.propertyImages[index].name}</div>
                      )}
                  </div>
                  <div className="heading4-parent d-flex flex-row justify-content-between row g-2">
                    <div className="col-md-6 text-start">
                      <div className="image-upload-label text-start my-2">
                        Length <span style={{ fontSize: '8px' }}>(SQ FT)</span>
                      </div>
                      <div className="frame-wrapper5 mt-2">
                        <div className="input-field2-wrapper">
                          <input
                            type="number"
                            className="input-field221"
                            placeholder="1200"
                            name={`propertyImages.${index}.length`}
                            value={data.sizeValue ? data.sizeValue : data.length}
                            onKeyDown={(e) => {
                              const validKeys = [
                                'Backspace',
                                'Delete',
                                'Tab',
                                'ArrowLeft',
                                'ArrowRight',
                                'Home',
                                'End',
                              ];
                              if (!validKeys.includes(e.key) && isNaN(Number(e.key))) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              // Prevent negative numbers
                              const value = Math.max(0, e.target.value || 0);
                              handlePropertyHeightChange({ target: { value: value } }, index);
                            }}
                          />
                        </div>
                      </div>
                      {formik.errors.propertyImages &&
                        formik.errors.propertyImages[index] &&
                        formik.errors.propertyImages[index].length && (
                          <div className="text-danger text-start">{formik.errors.propertyImages[index].length}</div>
                        )}
                    </div>
                    <div className="col-md-6 text-start">
                      <div className="image-upload-label text-start my-2">
                        Width <span style={{ fontSize: '8px' }}>(SQ FT)</span>
                      </div>
                      <div className="frame-wrapper5 mt-2">
                        <div className="input-field2-wrapper">
                          <input
                            type="number"
                            className="input-field221"
                            placeholder="1200"
                            name={`propertyImages.${index}.width`}
                            value={data.sizeValue ? data.sizeValue : data.width}
                            onKeyDown={(e) => {
                              const validKeys = [
                                'Backspace',
                                'Delete',
                                'Tab',
                                'ArrowLeft',
                                'ArrowRight',
                                'Home',
                                'End',
                              ];
                              if (!validKeys.includes(e.key) && isNaN(Number(e.key))) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              // Prevent negative numbers
                              const value = Math.max(0, e.target.value || 0);
                              handlePropertyWidthChange({ target: { value: value } }, index);
                            }}
                          />
                        </div>
                      </div>
                      {formik.errors.propertyImages &&
                        formik.errors.propertyImages[index] &&
                        formik.errors.propertyImages[index].width && (
                          <div className="text-danger text-start">{formik.errors.propertyImages[index].width}</div>
                        )}
                    </div>
                    {/* <div className="frame-parent6 col-md-4">
                      <div className="input-field2-parent12 mt-5">
                        <div className="mt-2">
                          <select
                            type="string"
                            className="input-field222"
                            name={`propertyImages.${index}.sizeUnit`}
                            value={data.sizeUnit}
                            onChange={(e) => handlePropertySizeUnitChange(e, index)}
                          >
                            <option value={''} hidden disabled></option>
                            <option value="sq feet">SQ FT</option>
                          </select>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              {formik.values.propertyImages.length > 2 && index > 1 && (
                <div
                  style={{ position: 'absolute', top: '-10px', right: '0', cursor: 'pointer' }}
                  onClick={() => {
                    handleRemovePropertyImage(index);
                  }}
                >
                  <CancleProperty />
                </div>
              )}
            </div>
          );
        })}
        {/* <div className="col-lg-4 col-md-6 mb-3" style={{ minHeight: '370px' }}>
          <button
            className="input-field2-parent13 w-100 g-0"
            onClick={() => addNewProperty()}
            disabled={formik.errors.propertyImages || isLoading}
          >
            <div className="input-field223">
              <div
                className="mt-5 d-flex justify-content-center align-items-center fs16"
                style={{
                  minHeight: '100px',
                  color: '#333333',
                }}
              >
                Add Another Room/Area of Property
              </div>
            </div>
            <div className="vector-icon-add">
              <AddPropertyIcon />
            </div>
          </button>
        </div> */}
      </div>
    </div>
  );
};

export default ImageUploadComponent;
