import React, { useState } from 'react';
import { NavDropdown, Spinner } from 'react-bootstrap';
import pptxgen from 'pptxgenjs';
import { processSlide } from '../../utils/helper';
import html2canvas from 'html2canvas';
import toast from 'react-hot-toast';
import jsPDF from 'jspdf';

const ExportButton = ({ propertyDetails }) => {
  const [isLoading, setIsLoading] = useState(false);

  const generatePowerPoint = async () => {
    try {
      // if (currentWorkspace.credits > 0) {
      setIsLoading(true);
      if (document.getElementsByClassName('newPptSlide')) {
        const slideArray = document.getElementsByClassName('newPptSlide');
        const pptx = new pptxgen();
        for (let i = 0; i < slideArray.length; i++) {
          const slide = pptx.addSlide();
          await processSlide(slideArray[i]);
          let canvas;

          const data = await html2canvas(slideArray[i], { dpi: 300 });
          canvas = data.toDataURL();

          if (canvas) {
            slide.addImage({
              data: canvas,
              w: '100%',
              h: '100%',
              x: 0,
              y: 0,
            });
          }
        }
        pptx.writeFile({ fileName: `${propertyDetails.name}` });
      }
      // await AxiosRequest.get("/property/updateUserCredits");
      toast.success('Presentation downloaded successfully');
      setTimeout(() => {
        setIsLoading(false);
        // window.location.reload();
      }, [1000]);
      // } else {
      //   toast.error("Please upgrade your plan for export presentation");
      // }
    } catch (error) {
      setIsLoading(false);
      toast.error('Failed to export as Presentation. Please try again later.');
      console.error('Capture Error:', error);
    }
  };

  const handleGeneratePDF = async () => {
    try {
      // if (currentWorkspace?.credits > 0) {
      setIsLoading(true);
      const slides = document.getElementsByClassName('newPptSlide');

      const pdf = new jsPDF({ unit: 'px', format: 'a4' });

      async function addSlideToPDF(index) {
        if (index < slides.length) {
          const slide = slides[index];
          await processSlide(slide);
          const canvas = await html2canvas(slide, { scale: 2 });

          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
          const imgHeight = (canvas.height * pdfWidth) / canvas.width;
          const topMargin = 20;

          if (index !== 0) {
            pdf.addPage();
          }

          pdf.setFillColor('#fff2f3');
          pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');

          let verticalPosition = topMargin;
          if (imgHeight + topMargin * 2 <= pdfHeight) {
            verticalPosition = (pdfHeight - imgHeight) / 2;
          }

          if (canvas) {
            pdf.addImage(canvas.toDataURL('image/jpeg', 1.0), 'JPEG', 0, verticalPosition / 2, pdfWidth, imgHeight);
          }
          await addSlideToPDF(index + 1);
        } else {
          pdf.save(`${propertyDetails.name}.pdf`);
          toast.success('PDF Download Successfully.');
          setIsLoading(false);
        }
      }

      await addSlideToPDF(0);
      // await AxiosRequest.get("/property/updateUserCredits");
      // } else {
      //   toast.error("Please upgrade your plan for exporting PDF");
      //   setIsLoading(false);
      // }
    } catch (error) {
      console.log('Error', error);

      setIsLoading(false);
      toast.error('Failed to export as PDF. Please try again later.');
      console.error('Error generating PDF:', error);
    }
  };

  return (
    <div className="btnNewPrimary ms-4" style={{ width: '120px' }}>
      <NavDropdown
        title={
          isLoading ? (
            <span>
              <Spinner animation="border" size="sm" className="me-2" />
              Wait
            </span>
          ) : (
            'Export'
          )
        }
        disabled={isLoading}
      >
        <NavDropdown.Item href="#" onClick={generatePowerPoint}>
          PPT
        </NavDropdown.Item>
        <NavDropdown.Item href="#" onClick={handleGeneratePDF}>
          PDF
        </NavDropdown.Item>
      </NavDropdown>
    </div>
  );
};

export default ExportButton;
