import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import React, { createContext, useCallback, useContext, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import AxiosInstance from "../utils/AxiosRequest";
import { toast } from "react-hot-toast";
import User from "./UserContext";

const PaymentMethodContext = createContext();

export const usePaymentMethodContext = () => {
  const context = useContext(PaymentMethodContext);
  if (!context) {
    throw new Error(
      "usePaymentMethodContext must be used within a PaymentMethodProvider"
    );
  }
  return context;
};

const PaymentMethodProvider = ({ children }) => {
  const { currentUser, _currentUser, currentWorkspace } = useContext(User);
  const stripe = useStripe();
  const elements = useElements();

  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [planId, setPlanId] = useState();

  const addNewCard = useCallback(
    async (event) => {
      setIsLoading(true);
      event.preventDefault();

      if (!stripe || !elements) {
        return;
      }

      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardNumberElement),
      });

      if (error) {
        toast.error(error.message);
        setIsLoading(false);
      } else {
        if (paymentMethod) {
          try {
            await AxiosInstance.post("/subscription/storeCustomerDetails", {
              paymentMethodId: paymentMethod.id,
              userId: currentUser && currentUser._id,
            });
            // toast.success(response.data.message);
            const result = await AxiosInstance.post("/subscription/create", {
              type: "subscription",
              planId: planId,
              userId: currentUser && currentUser._id,
              quantity: 1,
              baseURL: process.env.REACT_APP_BASE_URL,
              workspace: currentWorkspace && currentWorkspace._id
            });
            setTimeout(() => {
              toast.success(result.data.message);
              setShow(false);
              _currentUser();
              setIsLoading(false);
            }, [5000]);
          } catch (err) {
            console.log(err);
            toast.error(err?.response?.data?.message);
            setIsLoading(false);
          }
        }
      }
    },
    [stripe, elements, planId, currentUser]
  );

  return (
    <PaymentMethodContext.Provider
      value={{
        paymentModel: show,
        handlePaymentModel: setShow,
        handlePlanId: setPlanId,
      }}
    >
      <Modal show={show} onHide={() => setShow(false)} backdrop="static" centered className="modalCustomNew">
        <Modal.Header closeButton className="border-0 mx-3">
          Buy
        </Modal.Header>
        <Modal.Body className="pt-0">
          <div className="font14GrayModal">
            <div className="inputCustom d-flex align-items-center mb-3 p-0">
              <div className="w-100 text-start p-2">
                <label className="mb-1">Card Number</label>
                <CardNumberElement />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div className="inputCustom d-flex align-items-center mb-3 p-0">
                  <div className="w-100 text-start p-2">
                    <label className="mb-1">Expiry Date</label>
                    <CardExpiryElement />
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="inputCustom d-flex align-items-center mb-3 p-0">
                  <div className="w-100 text-start p-2">
                    <label className="mb-1">CVC</label>
                    <CardCvcElement />
                  </div>
                </div>
              </div>
            </div>
            <button
              whileTap={{ scale: 0.9 }}
              onClick={addNewCard}
              disabled={isLoading}
              className="btnNewPrimary w-100 mb-3"
            >
              {isLoading && <Spinner />} Buy
            </button>
          </div>
        </Modal.Body>
      </Modal>
      {children}
    </PaymentMethodContext.Provider>
  );
};

export default PaymentMethodProvider;
