import React, { useEffect, useState } from 'react';
import { PlusIcon } from '../../shared/NewIcon';
import { Spinner } from 'react-bootstrap';
import CreatePropertyLinkModel from '../../models/CreatePropertyLinkModel';

const CreateButton = ({ isCollapsed }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState(null);

  useEffect(() => {
    const currentUrl = window.location.href;
    const urlParams = new URL(currentUrl);
    const redirectUrlParam = urlParams.searchParams.get('redirectUrl');

    if (redirectUrlParam) {
      setRedirectUrl(redirectUrlParam); // Set the redirectUrl state
      setShowModal(true); // Automatically open the modal if redirectUrl is present
    }
  }, []);

  const handleClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setShowModal(true);
    }, 500);
  };

  return (
    <>
      <div className="mx-3 mt-2" style={{ marginBottom: '75px' }}>
        <button className="btnNewPrimary sm w-100" onClick={handleClick} disabled={isLoading}>
          {isLoading ? (
            <>
              <Spinner as="span" animation="border" size="sm" className="me-2" />
              Loading...
            </>
          ) : (
            <>
              {isCollapsed ? '' : 'Create'}
              <span>
                <PlusIcon />
              </span>
            </>
          )}
        </button>
      </div>

      <CreatePropertyLinkModel show={showModal} onHide={() => setShowModal(false)} redirectUrl={redirectUrl} />
    </>
  );
};

export default CreateButton;
