import React, { useState, useEffect } from 'react';
import AxiosRequest from '../../utils/AxiosRequest';
import { Card, ButtonGroup, Table, Nav, Dropdown } from 'react-bootstrap';
import {
  DropDownIcon,
  GridViewIcon,
  ListViewIcon,
  MobileVideoIcon,
  PresentationIcon,
  VideoIcon,
} from '../../shared/NewIcon';
import RecentTemplate from './RecentTemplate';

const VideoTemplateComponent = () => {
  const [videoTemplates, setVideoTemplates] = useState([]);
  const [playingVideoId, setPlayingVideoId] = useState(null);
  const [viewType, setViewType] = useState('grid'); // 'grid' or 'list'
  const [filterType, setFilterType] = useState('desktop');

  useEffect(() => {
    const fetchVideoTemplates = async () => {
      try {
        const response = await AxiosRequest.get('/property/video-template');
        setVideoTemplates(response?.data?.data || []);
      } catch (error) {
        console.error('Error fetching video templates:', error);
      }
    };

    fetchVideoTemplates();
  }, []);

  const handleVideoPlay = (templateId) => {
    setPlayingVideoId(templateId);
  };

  return (
    <>
      <div>
        <RecentTemplate videoTemplates={videoTemplates} />
      </div>

      <div>
        <h2 className="font24-medium-primary">All Templates</h2>

        {/* Filter and View Toggle Buttons */}
        <div className="d-flex justify-content-between align-items-center mb-3 gap-20">
          {/* <div>view all</div>
        <ButtonGroup className="switch-button">
          <button
            variant=""
            className={`switch ${filterType === 'video' ? 'active' : ''}`}
            onClick={() => setFilterType('video')}
          >
            Video
          </button>
          <button
            variant=""
            className={`switch ${filterType === 'presentation' ? 'active' : ''}`}
            onClick={() => setFilterType('presentation')}
          >
            Presentation
          </button>
        </ButtonGroup> */}

          <div>
            <Nav variant="pills" className="switch-pill my-4">
              <Nav.Item>
                <Nav.Link
                  className={filterType === 'presentation' ? 'active' : ''}
                  onClick={() => setFilterType('presentation')}
                >
                  <PresentationIcon />
                  <span className="ms-2">Presentation</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={filterType === 'desktop' ? 'active' : ''} onClick={() => setFilterType('desktop')}>
                  <VideoIcon />
                  <span className="ms-2">Video</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link className={filterType === 'mobile' ? 'active' : ''} onClick={() => setFilterType('mobile')}>
                  <MobileVideoIcon />
                  <span className="ms-2"> Mobile Video</span>
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </div>

          <div className="view-toggle">
            <div
              onClick={() => setViewType('grid')}
              className={`icon-wrapper ${viewType === 'grid' ? 'active' : ''}`}
              title="Grid View"
            >
              <GridViewIcon />
            </div>
            <div
              onClick={() => setViewType('list')}
              className={`icon-wrapper ${viewType === 'list' ? 'active' : ''}`}
              title="List View"
            >
              <ListViewIcon />
            </div>
          </div>
        </div>

        {/* Templates */}
        {viewType === 'grid' ? (
          <div className="row g-4">
            {videoTemplates &&
              videoTemplates.length > 0 &&
              videoTemplates.map((template) => (
                <div
                  key={template._id}
                  className={viewType === 'grid' ? 'col-sm-12 col-md-6 col-lg-4 col-xl-3' : 'mb-3'}
                >
                  <Card
                    className="cursor-pointer"
                    style={{
                      border: 0,
                    }}
                  >
                    <Card.Body className="p-0">
                      <div
                        className="video-container"
                        style={{
                          position: 'relative',
                          width: '100%',
                          backgroundColor: '#000',
                          borderRadius: '5px',
                        }}
                      >
                        {playingVideoId === template._id ? (
                          <video
                            width="100%"
                            height="100%"
                            style={{
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              borderRadius: '5px',
                            }}
                            controls
                            autoPlay
                          >
                            <source src={template.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <>
                            <img
                              src={template?.coverImageUrl}
                              alt="Video Thumbnail"
                              style={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                objectFit: 'fill',
                                borderRadius: '5px',
                              }}
                            />

                            <button
                              className="play-button"
                              onClick={(e) => {
                                e.stopPropagation(); // Prevent card selection
                                handleVideoPlay(template._id);
                              }}
                              style={{
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                                background: 'transparent',
                                border: 'none',
                                borderRadius: '50%',
                                padding: '10px 15px',
                                color: '#fff',
                                cursor: 'pointer',
                              }}
                            >
                              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="32" height="32">
                                <circle cx="32" cy="32" r="32" fill="#f1482d" />
                                <polygon points="25,18 25,46 46,32" fill="#fff" />
                              </svg>
                            </button>
                          </>
                        )}
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <div
                          style={{
                            fontSize: '12px',
                            fontWeight: '600',
                          }}
                        >
                          {template.name}
                        </div>
                        <div className="generation-action">
                          <Dropdown>
                            <Dropdown.Toggle variant="" id="dropdown-basic" className="bg-transparent">
                              <DropDownIcon />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Dropdown.Item href="#/action-3">Edit</Dropdown.Item>
                              <Dropdown.Item href="#/action-1">Rename</Dropdown.Item>
                              <Dropdown.Item href="#/action-2">Share</Dropdown.Item>
                              <Dropdown.Item href="#/action-3">pin</Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </div>
        ) : (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Template Name</th>
                <th>Type</th>
                <th>Preview</th>
              </tr>
            </thead>
            <tbody>
              {videoTemplates.map((template) => (
                <tr key={template._id}>
                  <td>{template.name}</td>
                  <td>{template.type}</td>
                  <td>
                    <div
                      style={{
                        width: '100px',
                        height: '100px',
                        position: 'relative',
                        borderRadius: '10px',
                        overflow: 'hidden',
                        background: '#000',
                      }}
                    >
                      {playingVideoId === template._id ? (
                        <video
                          width="100%"
                          height="100%"
                          controls
                          autoPlay
                          style={{
                            objectFit: 'cover',
                          }}
                          onClick={(e) => e.stopPropagation()} // Prevent table row selection
                        >
                          <source src={template.url} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      ) : (
                        <>
                          <img
                            src={template?.coverImageUrl}
                            alt="Video Thumbnail"
                            style={{
                              width: '100%',
                              height: '100%',
                              objectFit: 'cover',
                            }}
                          />
                          <button
                            onClick={(e) => {
                              e.stopPropagation(); // Prevent table row selection
                              handleVideoPlay(template._id);
                            }}
                            style={{
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              transform: 'translate(-50%, -50%)',
                              background: 'rgba(0, 0, 0, 0.5)',
                              border: 'none',
                              borderRadius: '50%',
                              padding: '5px',
                              cursor: 'pointer',
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" width="20" height="20">
                              <circle cx="32" cy="32" r="32" fill="#f1482d" />
                              <polygon points="25,18 25,46 46,32" fill="#fff" />
                            </svg>
                          </button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </div>
    </>
  );
};

export default VideoTemplateComponent;
