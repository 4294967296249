import { useContext, useEffect, useState } from "react";
import { usePaymentMethodContext } from "../../context/PaymentMethodContext";
import AxiosRequest from "../../utils/AxiosRequest";
import ContentSpinner from "../../shared/Spinner";
import { Container, Modal, Spinner } from "react-bootstrap";
import BannerHeader from "../../components/BannerHeader";
import User from "../../context/UserContext";
import { Link, useNavigate } from "react-router-dom";
import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import toast from "react-hot-toast";

const Pricing = () => {
  const [plans, setPlans] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const { handlePaymentModel, handlePlanId, paymentModel } = usePaymentMethodContext();
  const [currentPlan, setCurrentPlan] = useState();
  const [showCancelModal, setShowCancelModal] = useState(false);
  const { currentUser, _currentUser, currentWorkspace } = useContext(User);
  const [isCancel, setIsCancel] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionStorage.getItem('currentUser')) {
      _currentUser();
    }
  }, [sessionStorage.getItem('currentUser')]);

  useEffect(() => {
    if (currentUser) {
      fetchCurrentPlan();
    }
  }, [paymentModel, currentUser, currentWorkspace]);

  useEffect(() => {
    fetchAllPlans();
  }, [paymentModel]);

  const fetchCurrentPlan = async () => {
    try {
      const result = await AxiosRequest.post('/subscription/currentPlan', {
        // userId: currentUser && currentUser._id,
        workSpace: currentWorkspace && currentWorkspace._id,
      });

      setCurrentPlan(result?.data?.data);
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
    }
  };

  const fetchAllPlans = async () => {
    try {
      setIsLoading(true);
      const result = await AxiosRequest.get('/subscription/fetchAllPlans');
      setPlans(result?.data?.data);
      setIsLoading(false);
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      setIsLoading(false);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      setIsCancel(true);
      await AxiosRequest.post('/subscription/cancel', {
        subscriptionId: currentPlan.subscriptionId,
      });
      setShowCancelModal(false);
      setIsCancel(false);
      toast.success('Your subscription will be canceled at end of the period!.');
      fetchAllPlans();
    } catch (err) {
      setIsCancel(false);
      setShowCancelModal(false);
      toast.error(err?.response?.data?.message || err?.message);
    }
  };

  console.log('currentWorkspace', currentWorkspace);
  // console.log('currentPlan', currentPlan);
  return (
    <>
      {isLoading && <ContentSpinner />}
      {/* <Header /> */}
      {/* <BannerHeader heading="Pricing" /> */}
      <Container>
        <div className="pricing">
          <div className="subheading my-4">
            {!currentUser ? (
              <div className="fs30">
                Start Your <Link to="/add-property">Free Trial</Link> Today to Start Saving Time and Selling More.
              </div>
            ) : (
              <div className="fs30" style={{ maxWidth: '65%' }}>
                {currentUser && currentWorkspace && (
                  <>
                    {currentWorkspace?.credits === 0 && !currentPlan ? (
                      <>Your Free Trial has expired. Please buy a new plan or upgrade your current plan.</>
                    ) : currentWorkspace?.credits === 0 && currentPlan ? (
                      `Your ${currentPlan.plan.name} plan has expired. Please buy a new plan or
                      upgrade your current plan.`
                    ) : (
                      <>
                        Welcome! You are currently on a {currentWorkspace ? currentWorkspace.activePlan : 'Free trial'}{' '}
                        {currentPlan &&
                          currentPlan.plan.name !== 'One time' &&
                          `(${currentPlan.plan.interval === 'month' ? 'Monthly' : 'Annually'})`}{' '}
                        plan, and you have {`${currentWorkspace?.credits || 0} credits`} remaining.
                      </>
                    )}
                  </>
                )}
              </div>
            )}
          </div>
          <div className="frame-parent">
            <div className="row w-100 d-flex g-4">
              {plans.length > 0 &&
                plans.map((data, index) => {
                  return (
                    <div key={index} className="col-12 col-lg-6 col-xl-4">
                      <div className="instance-parent" style={{ padding: '20px', maxWidth: '264px' }}>
                        <div className="py-3 mt-3">
                          <div className="heading-2" style={{ textTransform: 'uppercase' }}>
                            {data.name}
                          </div>
                        </div>
                        <div className="mb-3">
                          <div className="planPrice text-start">
                            ${data.price}
                            {/* <br /> */}
                            <span>/{data.interval}</span>
                          </div>
                        </div>
                        <div className="subheading3 mb-3">
                          <div className="property-description-1-container">
                            <ul className="property-description-1-user-lo">
                              <li className="property-description">2000 Credits</li>
                              <li className="property-description">Description & Social Description</li>
                              <li className="property-description">Showcase Video</li>
                              <li className="property-description">Presentation</li>
                              <li className="property-description">24x7 Support</li>
                            </ul>
                          </div>
                        </div>
                        {currentWorkspace &&
                          currentUser &&
                          ((currentWorkspace.members.length > 0 &&
                            currentWorkspace.members.filter(
                              (data) => data.email === currentUser.email && data.role === 'admin'
                            ).length > 0) ||
                            currentWorkspace.user === currentUser._id) && (
                            <div>
                              {currentPlan ? (
                                data.priceId === currentPlan?.priceId ? (
                                  <button
                                    className="btnDanger"
                                    onClick={() => {
                                      if (currentUser) {
                                        setShowCancelModal(true);
                                      } else {
                                        navigate('/login');
                                      }
                                    }}
                                    disabled={isLoading}
                                  >
                                    Cancel
                                  </button>
                                ) : (
                                  <button
                                    className="btnNewPrimary"
                                    onClick={() => {
                                      if (currentUser) {
                                        handlePlanId(data._id);
                                        handlePaymentModel(true);
                                      } else {
                                        navigate('/login');
                                      }
                                    }}
                                    disabled={isLoading}
                                  >
                                    Upgrade Plan
                                  </button>
                                )
                              ) : (
                                <div
                                  className="btnBordered mb-3"
                                  onClick={() => {
                                    if (currentUser) {
                                      handlePlanId(data._id);
                                      handlePaymentModel(true);
                                    } else {
                                      navigate('/login');
                                    }
                                  }}
                                  disabled={isLoading}
                                >
                                  Start Now
                                </div>
                              )}
                            </div>
                          )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </Container>

      <Modal
        show={showCancelModal}
        backdrop="static"
        onHide={() => setShowCancelModal(false)}
        className="modalCustomNew"
      >
        <Modal.Header closeButton>Cancel subscription</Modal.Header>
        <Modal.Body>Are you sure you want to cancel your subscription?</Modal.Body>
        <Modal.Footer>
          <button className="btn btnBordered" onClick={() => setShowCancelModal(false)}>
            No
          </button>
          <button className="btn btnPrimary" onClick={handleCancelSubscription} disabled={isCancel}>
            {isCancel && <Spinner style={{ height: '20px', width: '20px' }} />} Yes
          </button>
        </Modal.Footer>
      </Modal>
      {/* <Footer /> */}
    </>
  );
};

export default Pricing;
