import toast from 'react-hot-toast';

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const options = { month: 'short', day: '2-digit', year: 'numeric' };
  return date.toLocaleDateString('en-US', options);
};
export const handleLogout = (navigate, setCurrentUser) => {
  sessionStorage.removeItem('currentUser');
  sessionStorage.removeItem('authToken');
  localStorage.removeItem('currentUser');
  toast.success('Logout successfully');

  setCurrentUser();
  window.location.href = 'https://restatecopilot.io/';
  // navigate('/');
  // window.location.reload();
};

export const getInitials = (fullName) => {
  if (!fullName || typeof fullName !== 'string') {
    return ''; // Return empty string if fullName is invalid
  }

  // Remove any extra spaces and split the name
  const names = fullName.trim().split(/\s+/);

  // Get first letter of first name and first letter of last name (if exists)
  const firstInitial = names[0] ? names[0][0] : '';
  const lastInitial = names[names.length - 1] ? names[names.length - 1][0] : '';

  // Combine and convert to uppercase
  return (firstInitial + lastInitial).toUpperCase();
};

export const extractShortName = (address) => {
  if (!address) return '';

  const commaCount = (address.match(/,/g) || []).length;
  const spaceCount = (address.match(/ /g) || []).length;
  let regexPattern;

  if (commaCount > 2) {
    regexPattern = /([^,\s]+(?: [^,\s]+)*)[^,]*,[^,]*,/;
  } else if (spaceCount > 2) {
    regexPattern = /([^,\s]+(?: [^,\s]+)*)(?:\s[^,\s]+){0,2}\s/;
  }

  if (regexPattern) {
    const matches = regexPattern.exec(address);
    if (matches && matches.length > 1) {
      return matches[1].trim();
    }
  }

  return address; // Fallback to full address if regex doesn't match
};
