import { useContext, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import User from "../context/UserContext";
import AxiosRequest from "../utils/AxiosRequest";
import toast from "react-hot-toast";

const UpgradePlanModel = ({ show, setShow, price }) => {
  const { currentUser } = useContext(User);
  const [planType, setPlanType] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    try {
      if (planType) {
        if (planType === "Subscription") {
          navigate("/pricing");
          setShow(false);
        } else {
          // setShow(false);
          setIsLoading(true);
          const result = await AxiosRequest.post("/subscription/create", {
            price,
            type: "onetime",
            planId: "65560ee0c03222c0bc2956ea",
            userId: currentUser && currentUser._id,
            quantity: 1,
            baseURL: `${process.env.REACT_APP_BASE_URL}${window.location.pathname}`,
          });
          if (result.data.data) {
            const newTab = window.open(result.data.data, "_blank");
            newTab.focus();
          }
          setIsLoading(false);
          setTimeout(() => {
            setShow(false);
          }, [5000]);
        }
      } else {
        toast.error("Please select a plan type");
      }
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  return (
    <Modal show={show} onHide={() => setShow(false)} backdrop="static" centered className="custom-otp-modal">
      <Modal.Header closeButton>
        <div className="heading410">
          <b className="home fs22">Upgrade Your Plan</b>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="heading4-parent8">
          <div className="mb-3">
            <div className="radio">
              <div className="ellipse-parent2" onClick={() => setPlanType('Subscription')}>
                <input type="radio" name="planType" checked={planType === 'Subscription'} />
                <label className="email-id ms-2 fs18">Subscription</label>
              </div>
              <div className="ellipse-parent2 ms-2" onClick={() => setPlanType('Onetime')}>
                <input type="radio" name="planType" checked={planType === 'Onetime'} />
                <label className="email-id ms-2 fs18">Onetime</label>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end align-items-end w-100">
            <button className="btnNewPrimary" onClick={() => handleSubmit()} disabled={isLoading}>
              {isLoading && <Spinner animation="border" size="sm" className="me-1" />} Submit
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UpgradePlanModel;
