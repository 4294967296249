import React, { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import User from "../../context/UserContext";
import BannerHeader from "../../components/BannerHeader";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import defaultAvtar from "../../assets/images/default-avtar.png";
import EditIcon from "../../assets/images/edit.png";
import AxiosRequest from "../../utils/AxiosRequest";
import { uploadImageWithSignUrl } from "../../utils/UploadImageWiths3";
import toast from "react-hot-toast";
import Spinner from "../../shared/Spinner";
import { isValidPhoneNumber } from "react-phone-number-input";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal } from "react-bootstrap";

const Profile = () => {
  const { currentUser, _currentUser } = useContext(User);
  const [edit, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);
  const [mobileError, setMobileError] = useState("");
  const [previewImage, setPreviewImage] = useState({
    image_name: "",
    image_file: null,
    image_url: "",
  });
  const [completedCrop, setCompletedCrop] = useState(null); // Final cropped area
  const [isShowCropImageModal, setIsShowCropImageModal] = useState(false);
  const [cropConfig, setCropConfig] = useState({
    unit: "%",
    width: 30,
    aspect: 16 / 9,
  });
  const [imageRef, setImageRef] = useState();

  // Define the initial form values and validation schema
  const initialValues = {
    firstName:
      currentUser && currentUser.name?.includes(" ")
        ? currentUser.name.split(" ")[0]
        : currentUser && currentUser.name,
    lastName:
      currentUser && currentUser.name?.includes(" ")
        ? currentUser.name.split(" ")[1]
        : "",
    email: currentUser && currentUser.email,
    countryCode: "+1",
    mobileNumber:
      currentUser && currentUser.mobileNumber ? currentUser.mobileNumber : "",
    photo: currentUser && currentUser.photo ? currentUser.photo : "",
    bio: currentUser && currentUser.bio ? currentUser.bio : "",
  };

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    countryCode: Yup.string(),
    mobileNumber: Yup.string(),
    photo: Yup.string().url("Invalid URL format"),
    bio: Yup.string(),
  });

  const handlePhoneNumber = (value) => {
    // The value contains the full international phone number

    if (value) {
      const isValid = isValidPhoneNumber(value);

      if (isValid) {
        formik.setFieldValue("mobileNumber", value);
        setMobileError(""); // Clear the error if the phone number is valid
      } else {
        setMobileError("Invalid mobile number"); // Set error for invalid phone number
      }
    } else {
      setMobileError("Mobile number is required"); // Set error if no phone number is entered
    }
  };

  const handleProfileImagehange = (e) => {
    const file = e.target.files[0];
    const ext = file.type.split("/");
    if (
      ext[1] === "png" ||
      ext[1] === "jpg" ||
      ext[1] === "jpeg" ||
      ext[1] === "webp"
    ) {
      setPreviewImage({
        image_name: file.name,
        image_file: file,
        image_url: URL.createObjectURL(e.target.files[0]),
      });
      setIsShowCropImageModal(true);
    } else {
    }
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { setSubmitting }) => {
      if (mobileError) {
        return;
      }
      setSubmitting(true);
      setIsLoading(true);
      try {
        if (edit) {
          if (
            previewImage.image_file &&
            String(previewImage.image_url).startsWith("blob")
          ) {
            const url = await uploadImageWithSignUrl(previewImage.image_file);
            values.photo = url;
          } else {
            values.photo = previewImage.image_url;
          }

          values.name = `${values.firstName} ${values.lastName}`;

          const result = await AxiosRequest.post("/user/profile", {
            ...values,
          });
          toast.success(result.data.message);
          _currentUser();
          setIsLoading(false);
          setMobileError("");
        }
      } catch (err) {
        toast.error(err?.response?.data?.message || err?.message);
        setIsLoading(false);
        setMobileError("");
      }
    },
  });

  useEffect(() => {
    if (currentUser && currentUser.photo) {
      setPreviewImage({
        image_name: "",
        image_file: null,
        image_url: currentUser.photo,
      });
    }
  }, [currentUser]);

  const onCropChange = (newCrop) => {
    setCropConfig(newCrop);
  };

  const onCropComplete = (crop) => {
    setCompletedCrop(crop);
    setCropConfig(crop);
  };

  function getCroppedImage(sourceImage, cropConfig, fileName) {
    // creating the cropped image from the source image
    const canvas = document.createElement("canvas");
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        // returning an error
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }

        blob.name = fileName;
        // creating a Object URL representing the Blob object given
        const croppedImageUrl = window.URL.createObjectURL(blob);

        resolve(croppedImageUrl);
      }, "image/jpeg");
    });
  }

  async function cropImage(crop) {
    if (imageRef && crop.width && crop.height) {
      const croppedImage = await getCroppedImage(
        imageRef,
        crop,
        "croppedImage.jpeg"
      );

      if (croppedImage) {
        setPreviewImage({
          ...previewImage,
          image_url: croppedImage,
        });
        setIsShowCropImageModal(false);
      }
    }
  }

  return (
    <>
      {isLoading && <Spinner />}
      <div className="pricing">
        {/* <BannerHeader heading="Profile" /> */}
        <div className="container py-5 text-start">
          {/* Profile picture integration starts */}

          <div className="d-flex justify-content-center">
            <div className="position-relative col-lg-auto">
              <img
                src={previewImage.image_url ? previewImage.image_url : defaultAvtar}
                alt="Preview"
                className="bordered-image"
                style={{
                  height: '100px',
                  width: '100px',
                }}
              />

              {edit ? (
                <>
                  <label htmlFor="uploadProfile">
                    <div className="d-flex justify-content-center align-items-center position-absolute edit-image cursor-pointer">
                      <img src={EditIcon} alt="" />
                    </div>
                  </label>

                  <input
                    type="file"
                    accept="image/*"
                    id="uploadProfile"
                    name="photo"
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={(e) => {
                      handleProfileImagehange(e);
                    }}
                  />
                </>
              ) : null}
            </div>
          </div>

          <div className="d-flex justify-content-center">
            <div className="font30Blue">
              {formik.initialValues.firstName} {formik.initialValues.lastName}
            </div>
          </div>

          <div className="d-flex justify-content-center mt-3">
            <button className="btnNewPrimary sm" onClick={() => setEdit(!edit)}>
              Edit Profile
            </button>
          </div>

          {/* Profile picture integration ends */}

          <form onSubmit={formik.handleSubmit}>
            <div className="row mt-md-5 sm-mt-4 mt-2">
              <div className="col-12 col-lg-4 mb-3">
                <label htmlFor="firstName" className="form-label font20">
                  First Name
                </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  className="form-control input-field25"
                  disabled={!edit}
                  {...formik.getFieldProps('firstName')}
                  placeholder="First Name"
                />
                {formik.touched.firstName && formik.errors.firstName && (
                  <div className="text-danger text-start ms-2" style={{ fontSize: '16px' }}>
                    {formik.errors.firstName}
                  </div>
                )}
              </div>
              <div className="col-12 col-lg-4 mb-3">
                <label htmlFor="lastName" className="form-label font20">
                  Last Name
                </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  className="form-control input-field25"
                  disabled={!edit}
                  {...formik.getFieldProps('lastName')}
                  placeholder="Last Name"
                />
                {formik.touched.lastName && formik.errors.lastName && (
                  <div className="text-danger text-start ms-2" style={{ fontSize: '16px' }}>
                    {formik.errors.lastName}
                  </div>
                )}
              </div>
              <div className="col-12 col-lg-4 mb-3">
                <label htmlFor="email" className="form-label font20">
                  Email ID
                </label>
                <input
                  type="text"
                  id="email"
                  name="email"
                  className="form-control input-field25"
                  disabled
                  {...formik.getFieldProps('email')}
                  placeholder="Email ID"
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="text-danger text-start ms-2" style={{ fontSize: '16px' }}>
                    {formik.errors.email}
                  </div>
                )}
              </div>
              <div className="col-12 col-lg-4 mb-3">
                <label htmlFor="mobileNumber" className="form-label font20">
                  Mobile Number
                </label>
                <div className="phone-input-container">
                  <PhoneInput
                    className="form-control inputCustom"
                    international
                    defaultCountry="US"
                    value={formik.values.mobileNumber}
                    onChange={handlePhoneNumber}
                    id="mobileNumber"
                    disabled={!edit}
                  />
                </div>
                {mobileError ? (
                  <div className="text-danger ms-2" style={{ fontSize: '16px' }}>
                    {mobileError}
                  </div>
                ) : null}
              </div>
              <div className="col-12 col-lg-4 mb-3">
                <label htmlFor="mobileNumber" className="form-label font20">
                  Bio
                </label>
                <textarea
                  rows="3"
                  id="bio"
                  name="bio"
                  className="form-control input-field25"
                  disabled={!edit}
                  {...formik.getFieldProps('bio')}
                  placeholder="Bio"
                />
              </div>
            </div>
            <button
              type="submit"
              className="btnNewPrimary mt-2"
              disabled={isLoading || Object.keys(formik.errors).length > 0 || mobileError || !edit}
              onClick={formik.handleSubmit}
            >
              Save
            </button>
          </form>
          {isShowCropImageModal && (
            <Modal show={isShowCropImageModal} onHide={() => setIsShowCropImageModal(false)}>
              <Modal.Body>
                <div className="text-center">
                  {previewImage.image_url && (
                    <div>
                      <ReactCrop
                        src={previewImage.image_url}
                        crop={cropConfig}
                        ruleOfThirds
                        onImageLoaded={(imageRef) => setImageRef(imageRef)}
                        onChange={onCropChange}
                        onComplete={onCropComplete}
                        crossorigin="anonymous" // to avoid CORS-related problems
                      />
                      <br />
                      {completedCrop && (
                        <button onClick={() => cropImage(cropConfig)} className="btnNewPrimary mt-4">
                          Crop Image
                        </button>
                      )}
                      <br />
                      <br />
                    </div>
                  )}
                </div>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </div>
    </>
  );
};

export default Profile;
