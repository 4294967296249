import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";
import { IoIosImage } from "react-icons/io";
import storeData from "./LinkedList";
import { CancleProperty } from "../../shared/Icons";

const EditImageModal = (props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [completedCrop, setCompletedCrop] = useState(null); // Final cropped area
  const [rotation, setRotation] = useState(0);
  const [state, setState] = useState({
    image: "",
    brightness: 100,
    grayscale: 0,
    sepia: 0,
    saturate: 100,
    contrast: 100,
    hueRotate: 0,
    rotate: 0,
    vartical: 1,
    horizental: 1,
  });
  const filterElement = [
    {
      name: "brightness",
      maxValue: 200,
    },
    {
      name: "grayscale",
      maxValue: 200,
    },
    {
      name: "sepia",
      maxValue: 200,
    },
    {
      name: "saturate",
      maxValue: 200,
    },
    {
      name: "contrast",
      maxValue: 200,
    },
    {
      name: "hueRotate",
    },
  ];

  const onCropComplete = async (croppedArea, croppedAreaPixels) => {
    setCompletedCrop(croppedAreaPixels);
  };

  // const handleFileChange = async (e) => {
  //   // props.setIsShowCropImageModal({ show: true, col: props.isShowCropImageModal.col })
  //   if (e && e.target.files[0]) {
  //     props.setIsShowCropImageModal({
  //       show: true,
  //       col: props.isShowCropImageModal.col,
  //     });
  //     const file = e.target.files[0];
  //     const SelectedFile = await readFile(file);
  //     setImagesToCrop({
  //       image_Url: SelectedFile,
  //       image_file: file,
  //       image_name: file.name,
  //     }); // Queue the new images for cropping
  //   }
  // };
  const handleCrop = async () => {
    if (props.ImagesToCrop && props.ImagesToCrop?.image_Url) {
      const croppedImage = await getCroppedImg(
        props.ImagesToCrop.image_Url,
        completedCrop,
        rotation,
        state
      );
      const selectedfile = new File([croppedImage], props.ImagesToCrop.image_name, {
        type: props.ImagesToCrop.image_file.type,
      });
      await props.onSelectFile(selectedfile, props.isShowCropImageModal.col);
      props.setIsShowCropImageModal({
        show: false,
        col: props.isShowCropImageModal.col,
      });
      props.setModalShow(true);
      resetState();
      // Do something with the cropped image (e.g., set it in state, upload, etc.)
    }
  };
  const resetState = () => {
    props.setImagesToCrop({});
    setZoom(1);
    setRotation(0);
    setState({
      image: "",
      brightness: 100,
      grayscale: 0,
      sepia: 0,
      saturate: 100,
      contrast: 100,
      hueRotate: 0,
      rotate: 0,
      vartical: 1,
      horizental: 1,
    });
  };
  const CancelCard = () => {
    props.setIsShowCropImageModal({
      show: false,
      col: props.isShowCropImageModal.col,
    });
    props.setImagesToCrop({});
    resetState();
  };
  const [property, setProperty] = useState({
    name: "brightness",
    maxValue: 200,
  });
  const inputHandle = (e) => {
    const newvalue = parseFloat(e.target.value);
    setState({
      ...state,
      [e.target.name]: newvalue,
    });
  };
  const leftRotate = () => {
    setState({
      ...state,
      rotate: state.rotate - 90,
    });

    const stateData = state;
    stateData.rotate = state.rotate - 90;
    storeData.insert(stateData);
  };

  const rightRotate = () => {
    setState({
      ...state,
      rotate: state.rotate + 90,
    });
    const stateData = state;
    stateData.rotate = state.rotate + 90;
    storeData.insert(stateData);
  };
  const varticalFlip = () => {
    setState({
      ...state,
      vartical: state.vartical === 1 ? -1 : 1,
    });
    const stateData = state;
    stateData.vartical = state.vartical === 1 ? -1 : 1;
    storeData.insert(stateData);
  };

  const horizentalFlip = () => {
    setState({
      ...state,
      horizental: state.horizental === 1 ? -1 : 1,
    });
    const stateData = state;
    stateData.horizental = state.horizental === 1 ? -1 : 1;
    storeData.insert(stateData);
  };
  // useEffect(() => {
  //   props.setIsShowCropImageModal({ show: false, col: props.isShowCropImageModal.col })
  // }, [])
  return (
    <>
      {/* {props.isFileInputVisible && (
        <input onChange={handleFileChange} style={{ display: 'none' }} type="file" id='choose' accept="image/png, image/jpeg, image/jpg, image/webp" />

      )} */}
      <Modal
        show={props.isShowCropImageModal.show}
        onHide={() =>
          props.setIsShowCropImageModal({
            show: false,
            col: props.isShowCropImageModal.col,
          })
        }
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
      >
        <Modal.Body>
          <div>
            <div className="d-flex justify-content-end me-2" onClick={CancelCard}>
              <CancleProperty />
            </div>
            <div className="ImageEditorcard">
              <div className="card_body">
                <div className="imageCrop-sidebar">
                  {/* Filters */}
                  {props.isShowCropImageModal.show && (
                    <div className="side_body">
                      <div className="filter_section">
                        <span>Filters</span>
                        <div className="filter_key">
                          {filterElement.map((v, i) => (
                            <button
                              className={property.name === v.name ? 'active' : ''}
                              onClick={() => setProperty(v)}
                              key={i}
                            >
                              {v.name}
                            </button>
                          ))}
                        </div>
                      </div>
                      <div className="filter_slider">
                        <div className="label_bar">
                          <label htmlFor="range">Range</label>
                          <span> {state[property.name]}%</span>
                        </div>
                        <input
                          name={property.name}
                          onChange={inputHandle}
                          value={state[property.name]}
                          max={property.maxValue}
                          type="range"
                        />
                      </div>
                      <div className="filter_slider">
                        <div className="label_bar">
                          <label htmlFor="range">Rotate</label>
                          <span> {rotation}%</span>
                        </div>
                        <input
                          name={'Rotate'}
                          onChange={(e) => setRotation(e.target.value)}
                          value={rotation}
                          min={0}
                          max={360}
                          type="range"
                        />
                      </div>
                      <div className="filter_slider">
                        <div className="label_bar">
                          <label htmlFor="range">Zoom</label>
                          <span> {zoom}</span>
                        </div>
                        <input
                          name={'Rotate'}
                          onChange={(e) => setZoom(e.target.value)}
                          value={zoom}
                          min={1}
                          max={3}
                          step={0.1}
                          type="range"
                        />
                      </div>

                      {/* <div className="rotate">
                      <label htmlFor="">Rotate & Filp</label>
                      <div className="icon">
                        <div onClick={leftRotate}><GrRotateLeft /></div>
                        <div onClick={rightRotate}><GrRotateRight /></div>
                        <div onClick={varticalFlip}><CgMergeVertical /></div>
                        <div onClick={horizentalFlip}><CgMergeHorizontal /></div>
                      </div>
                    </div> */}
                    </div>
                  )}

                  {/* chose image & cancel buttons */}
                  <div className="image_select">
                    {/* <button onClick={CancelCard} className="cancelBtn">
                      Cancel
                    </button> */}

                    <label className="SelectImageBtn" htmlFor={`choose-${props.selectedItemIndex}`}>
                      {Object.keys(props.ImagesToCrop)?.length > 0 ? 'Change' : 'Choose'} Image
                    </label>
                    <input
                      onChange={props.handleFileChange}
                      type="file"
                      id={`choose-${props.selectedItemIndex}`}
                      accept="image/png, image/jpeg, image/jpg, image/webp"
                    />
                  </div>
                </div>
                <div className="image_section ms-md-3">
                  {/* modal */}
                  <div
                    className="image"
                    style={{
                      position: 'relative',
                      filter: `brightness(${state.brightness}%) sepia(${state.sepia}%) saturate(${state.saturate}%) contrast(${state.contrast}%) grayscale(${state.grayscale}%) hue-rotate(${state.hueRotate}deg)`,
                      transform: `scale(${state.vartical},${state.horizental})`,
                    }}
                  >
                    {props.ImagesToCrop && props.ImagesToCrop?.image_Url ? (
                      <Cropper
                        image={props.ImagesToCrop?.image_Url}
                        crop={crop}
                        zoom={zoom}
                        aspect={1 / 1}
                        onCropChange={setCrop}
                        onCropComplete={onCropComplete}
                        onZoomChange={setZoom}
                        rotation={rotation}
                        onRotationChange={setRotation}
                      />
                    ) : (
                      <label htmlFor={`choose-${props.selectedItemIndex}`}>
                        <IoIosImage />
                        <span>Choose Image</span>
                      </label>
                    )}
                  </div>
                  {/* Reset button */}
                  <div className="reset">
                    <button className="resetBtn" onClick={() => resetState()}>
                      Reset
                    </button>
                    <button className="saveBtn" onClick={() => handleCrop()}>
                      Save Image
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditImageModal;
