import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { BellIcon, BellIconBig, CheckIcon } from '../../shared/Icons';
import User from '../../context/UserContext';
import React, { useContext, useEffect } from 'react';
import moment from 'moment';
import AxiosRequest from '../../utils/AxiosRequest';
import InfiniteScroll from 'react-infinite-scroll-component';
import defaultAvtar from '../../assets/images/default-avtar.png';
import toast from 'react-hot-toast';
import LogoImage from '../../assets/images/Logo.png';
import { handleLogout } from '../../utils/common';
import WorkspaceSettingPopup from '../Dashboard/WorkspaceSettingPopup';

export const Header = () => {
  const {
    currentUser,
    notifications,
    hasMore,
    containerRef,
    loadmore,
    handleScroll,
    notificationCount,
    setNotifications,
    setPage,
    setNotificationCount,
    setHasMore,
    getNotifications,
    page,
    setCurrentUser,
    isLoading,
    workspacePopup,
    setWorkspacePopup,
    allWorkspaces,
    getAllWorkspaces,
  } = useContext(User);
  const navigate = useNavigate();
  const location = useLocation();
  const isRouteActive = (prefix) => {
    return location.pathname.startsWith(prefix);
  };

  const onLogoutClick = () => {
    handleLogout(navigate, setCurrentUser);
  };

  // const handleLogout = () => {
  //   sessionStorage.removeItem('currentUser');
  //   sessionStorage.removeItem('authToken');
  //   localStorage.removeItem('currentUser');
  //   toast.success('Logout successfully');
  //   setCurrentUser();
  //   // window.location.href = 'https://restatecopilot.io/';
  //   navigate('/');
  //   window.location.reload();
  // };

  const handleMarkAllAsRead = async () => {
    try {
      const id = [];
      for (let i = 0; i < notifications.length; i++) {
        if (notifications[i].isRead === false) {
          id.push(notifications[i]._id);
        }
      }
      await AxiosRequest.post('/notification/updateNotification', { id: id });
      const updatedNotifications = notifications.filter((notification) => notification.isRead);
      setNotifications(updatedNotifications);
      getNotifications();
      setNotificationCount(0);
      setPage(1);
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      console.log(err);
    }
  };

  const handleUpdateNotification = async (notificationId) => {
    try {
      const id = [notificationId];
      await AxiosRequest.post('/notification/updateNotification', { id: id });
      const updatedNotifications = notifications.map((notification) => {
        if (notification._id === notificationId && !notification.isRead) {
          notification.isRead = true;
          setNotificationCount((prevCount) => prevCount - 1);
        }
        return notification;
      });
      setNotifications(updatedNotifications);
      getNotifications();
      setPage(1);
    } catch (err) {
      toast.error(err?.response?.data?.message || err?.message);
      console.log(err);
    }
  };

  useEffect(() => {
    if (currentUser) getAllWorkspaces({ query: null });
  }, [currentUser]);

  useEffect(() => {
    window.addEventListener('scroll', function () {
      const navbarBgElement = document.querySelector('.navbar-bg');
      if (navbarBgElement) {
        if (window.scrollY > 20) {
          navbarBgElement.classList.add('scrolled');
        } else {
          navbarBgElement.classList.remove('scrolled');
        }
      }
    });
  }, []);

  return (
    <>
      <Navbar expand="lg" className=" navbar-bg fixed-top" id="navbar">
        <Container>
          {/* <Navbar.Brand
          onClick={() => navigate("/")}
          style={{ cursor: "pointer" }}
          >
            <img src={LogoImage} alt="" />
          </Navbar.Brand> */}

          <a href={`https://restatecopilot.io/?isVerified=${currentUser?.isVerified}`}>
            <img src={LogoImage} alt="Restate Logo" />
          </a>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <div className="navlink-parent">
                {currentUser !== null && currentUser !== undefined && (
                  <Link className={`navlink${isRouteActive('/my-generation') ? '-active' : ''}`} to="/my-generation">
                    <div className="home">My Listings</div>
                  </Link>
                )}
                <Link className={`navlink${isRouteActive('/pricing') ? '-active' : ''}`} to="/pricing">
                  <div className="home">Pricing</div>
                </Link>
                <Link className={`navlink${isRouteActive('/faq') ? '-active' : ''}`} to="/faq">
                  <div className="home">FAQ</div>
                </Link>
                <Link className={`navlink${isRouteActive('/contact') ? '-active' : ''}`} to="/contact">
                  <div className="home">Contact Us</div>
                </Link>
                {currentUser !== null && currentUser !== undefined && (
                  <NavDropdown
                    className="position-relative navNotifications"
                    align="end"
                    title={
                      <div
                        onClick={() => {
                          if (page === 1) {
                            getNotifications();
                            setHasMore(true);
                          } else {
                            setHasMore(true);
                            setPage(1);
                          }
                        }}
                      >
                        {notificationCount > 0 && <div className="notificationNum">{notificationCount}</div>}
                        <BellIcon />
                      </div>
                    }
                    id="basic-nav-dropdown"
                  >
                    <div className="w-100">
                      <div className="heading w-100 text-center">Notifications</div>
                      {notificationCount > 0 && (
                        <div
                          onClick={() => {
                            setHasMore(true);
                            handleMarkAllAsRead();
                          }}
                          className="text-end m-2"
                          style={{ width: '95%', cursor: 'pointer' }}
                        >
                          Mark all as read
                        </div>
                      )}
                      {notifications?.length === 0 && (
                        <div className="text-center p-5">
                          <BellIconBig />
                          <p style={{ whiteSpace: 'nowrap' }} className="mt-3">
                            No Notification Yet
                          </p>
                        </div>
                      )}
                    </div>
                    {/* <NavDropdown.Divider /> */}
                    {notifications?.length > 0 && (
                      <div ref={containerRef} className="notifications-container" onScroll={handleScroll}>
                        <InfiniteScroll dataLength={notifications.length} next={loadmore} hasMore={hasMore}>
                          {notifications.map((data, index) => {
                            return (
                              <NavDropdown.Item
                                key={index}
                                className="mb-1 py-2"
                                style={{
                                  backgroundColor: data.isRead === false ? '#ededed' : 'white',
                                  whiteSpace: 'wrap',
                                }}
                              >
                                <div
                                  onClick={() => {
                                    if (data.isRead === false) {
                                      setHasMore(true);
                                      handleUpdateNotification(data._id);
                                    }
                                    if (data?.property) {
                                      navigate(`/property-details/${data.property}`);
                                    }
                                  }}
                                >
                                  <div className="title">{data.title}</div>
                                  <div className="date">{moment(data.createdAt).format('DD MMM YYYY, hh:mm:s')}</div>
                                </div>
                              </NavDropdown.Item>
                            );
                          })}
                        </InfiniteScroll>
                      </div>
                    )}
                  </NavDropdown>
                )}
                {!isLoading && (
                  <>
                    {currentUser ? (
                      <ProfileDropdown
                        getAllWorkspaces={getAllWorkspaces}
                        allWorkspaces={allWorkspaces}
                        currentUser={currentUser}
                        handleLogout={onLogoutClick}
                        setWorkspacePopup={setWorkspacePopup}
                      />
                    ) : (
                      <>
                        {!sessionStorage.getItem('currentUser') && (
                          <>
                            <Link className="navlink" to="/login">
                              <div className="home">Login</div>
                            </Link>
                            <Link className="" to="/signup">
                              <button className="btnNewPrimary">Free Trial</button>
                            </Link>
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* ====== Workspace Setting Popup ======= */}
      <WorkspaceSettingPopup
        workspacePopup={workspacePopup}
        setWorkspacePopup={setWorkspacePopup}
        hide={() => setWorkspacePopup(null)}
        currentUser={currentUser}
        getAllWorkspaces={getAllWorkspaces}
      />
    </>
  );
};

const ProfileDropdown = ({ getAllWorkspaces, allWorkspaces, currentUser, handleLogout, setWorkspacePopup }) => {
  const navigate = useNavigate();
  const { currentWorkspace, setCurrentWorkspace, handleSelectCurrentWorkspace } = useContext(User);
  return (
    <NavDropdown
      align="end"
      className="profileDropdown _customprofileClass"
      title={
        <div
          role="button"
          onClick={() => {
            const _customprofileClass = document?.querySelector('._customprofileClass');
            const show = _customprofileClass?.querySelector('.dropdown-menu')?.classList?.contains('show');
            if (!show) getAllWorkspaces({ query: null });
          }}
          className="d-flex justify-content-center align-items-center"
        >
          <div>
            <img
              src={currentUser.photo || defaultAvtar}
              width={'41px'}
              height={'40px'}
              alt=""
              style={{
                borderRadius: '50%',
                objectFit: 'cover',
              }}
            />
          </div>
          <div className="ms-2" style={{ fontSize: '24px' }}>
            {currentUser.name}
          </div>
        </div>
      }
      id="basic-nav-dropdown"
    >
      <NavDropdown.Item className="_item" href="/profile">
        Profile
      </NavDropdown.Item>
      <hr className="mt-2" />
      <div className="dropdownWorkspaceArea">
        <h6 className="mb-1 titleTop">Workspaces</h6>
        {allWorkspaces.loading
          ? 'Loading...'
          : allWorkspaces?.data?.length === 0
          ? 'no data'
          : allWorkspaces?.data?.map((item, i) => (
              <div key={i} className="workspaceList d-flex align-items-center w-100">
                <span role="button" onClick={() => handleSelectCurrentWorkspace(item)} className="profileImg">
                  <img src={item?.image_url || defaultAvtar} />
                </span>
                <div className="d-flex flex-column ms-2">
                  <div className="d-flex align-items-center">
                    <span role="button" onClick={() => handleSelectCurrentWorkspace(item)} className="title">
                      {item.name}
                    </span>
                    {item._id === currentWorkspace?._id ? (
                      <span className="ms-2 active">
                        <CheckIcon />
                      </span>
                    ) : null}
                  </div>
                  <span role="button" onClick={() => setWorkspacePopup(item._id)} className="option">
                    Settings & Members
                  </span>
                </div>
              </div>
            ))}
        <h6 role="button" onClick={() => navigate('/all-workspaces')} className="mb-0 mt-2 titleBottom">
          All Workspaces
        </h6>
      </div>
      <hr className="mb-2" />
      <NavDropdown.Item className="_item" href="#" onClick={handleLogout}>
        Logout
      </NavDropdown.Item>
    </NavDropdown>
  );
};
