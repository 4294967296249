import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import "jspdf-autotable";
import pptxgen from "pptxgenjs";
import { Breadcrumb, Col, Container, NavDropdown, Row } from "react-bootstrap";
import ContentEditable from "react-contenteditable";
import toast from "react-hot-toast";
import ContactUsImg from "../../assets/images/ContactUs.png";
import realtixLogoOrange from "../../assets/images/Logo.png";
import {
  AtticIcon,
  BackyardIcon,
  BasementIcon,
  BathIcon,
  BathroomIcon,
  BedRoomIcon,
  ClosetIcon,
  DiningIcon,
  DollarIcon,
  ExteriorIcon,
  GarageIcon,
  HallwayIcon,
  HomeOfficeIcon,
  HomePlanIcon,
  KitchenIcon,
  LaundryIcon,
  LivingRoomIcon,
  ParkingIcon,
  PatioIcon,
  PorchIcon,
  PropertySizeIcon,
  TranscationTypeIcon,
} from '../../shared/Icons';
import { processSlide } from "../../utils/helper";
import houseImg1 from "./images/houseImg1.png";

const Template3 = ({
  propertyDetails,
  isEdit,
  setIsEdit,
  imageSrc,
  nearByPlaces,
  handleSaveDesc,
  editedDescription,
  handleDescriptionChange,
  generateStars,
  coordinates,
  mapContainerStyle,
  currentUser,
  setIsLoading,
  staticMapURL,
  template,
  setTemplate,
  params,
  isGenerationOwner,
  currentWorkspace
}) => {
  const getIconByName = (name) => {
    const smallCaseName = name.toLowerCase();

    if (smallCaseName === 'exterior') {
      return <ExteriorIcon />;
    } else if (smallCaseName === 'kitchen') {
      return <KitchenIcon />;
    } else if (smallCaseName === 'living_room') {
      return <LivingRoomIcon />;
    } else if (smallCaseName === 'bedroom') {
      return <BedRoomIcon />;
    } else if (smallCaseName === 'garden') {
      return <LivingRoomIcon />;
    }
    // ================================================================
    else if (smallCaseName === 'backyard') {
      return <BackyardIcon />;
    } else if (smallCaseName === 'basement') {
      return <BasementIcon />;
    } else if (smallCaseName === 'bathroom') {
      return <BathroomIcon />;
    } else if (smallCaseName === 'dining_room') {
      return <DiningIcon />;
    } else if (smallCaseName === 'home_office') {
      return <HomeOfficeIcon />;
    } else if (smallCaseName === 'home_plan') {
      return <HomePlanIcon />;
    } else if (smallCaseName === 'patio') {
      return <PatioIcon />;
    } else if (smallCaseName === 'laundry_room') {
      return <LaundryIcon />;
    } else if (smallCaseName === 'garage') {
      return <GarageIcon />;
    } else if (smallCaseName === 'attic') {
      return <AtticIcon />;
    } else if (smallCaseName === 'hallway') {
      return <HallwayIcon />;
    } else if (smallCaseName === 'porch') {
      return <PorchIcon />;
    } else if (smallCaseName === 'closet') {
      return <ClosetIcon />;
    }
  };
  const _handleGeneratePDF = async () => {
    try {
      if (currentWorkspace?.credits > 0) {
        setIsLoading(true);
        const slides = document.querySelectorAll(".templateBg_2");

        // Create a jsPDF instance
        const pdf = new jsPDF();
        // Function to loop through each slide and add it to the PDF
        async function addSlideToPDF(index) {
          if (index < slides.length) {
            const slide = slides[index];
            await processSlide(slide);
            const canvas = await html2canvas(slide);
            // const imageData = canvas.toDataURL('image/jpeg', 1.0);
            let imgData;
            // if (index === slides.length - 1) {
            //   const response = await fetch(staticMapURL);
            //   const blob = await response.blob();
            //   imgData = URL.createObjectURL(blob);
            // } else {
            imgData = canvas.toDataURL("image/jpeg", 1.0);
            // }
            // Set PDF page height as per slide height
            const pdfHeight =
              (pdf.internal.pageSize.getWidth() / canvas.width) * canvas.height;

            // Add a new page for slides starting from the second slide
            pdf.addPage([pdf.internal.pageSize.getWidth(), pdfHeight]);
            if (imgData) {
              pdf.addImage(
                imgData,
                "JPEG",
                0,
                0,
                pdf.internal.pageSize.getWidth(),
                pdfHeight
              );
            }

            // Process next slide
            await addSlideToPDF(index + 1);
          } else {
            // Save the PDF
            pdf.deletePage(1);
            pdf.save(`${propertyDetails.name}.pdf`);
            toast.success("PDF Download Successfully.");
            // window.location.reload();
            setIsLoading(false);
          }
        }

        // Start adding slides to the PDF
        await addSlideToPDF(0);
        // await AxiosRequest.get("/property/updateUserCredits");
      } else {
        toast.error("Please upgrade your plan for exporting PDF");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error generating PDF:", error);
    }
  };

  const generatePowerPoint = async () => {
    try {
      // if (currentWorkspace.credits > 0) {
      setIsLoading(true);
      const slideArray = document.querySelectorAll(".templateBg_2");
      const pptx = new pptxgen();
      for (let i = 0; i < slideArray.length; i++) {
        const slide = pptx.addSlide();
        await processSlide(slideArray[i]);
        let canvas;
        // if (i === slideArray.length - 1) {
        //   const response = await fetch(staticMapURL);
        //   const blob = await response.blob();
        //   const reader = new FileReader(); // Define the reader here
        //   const base64Image = await new Promise((resolve) => {
        //     reader.onloadend = () => resolve(reader.result);
        //     reader.readAsDataURL(blob);
        //   });
        //   canvas = base64Image;
        // } else {
        const data = await html2canvas(slideArray[i], { dpi: 300 });
        canvas = data.toDataURL();
        // }
        if (canvas) {
          slide.addImage({
            data: canvas,
            w: "100%",
            h: "100%",
            x: 0,
            y: 0,
          });
        }
      }
      pptx.writeFile({ fileName: `${propertyDetails.name}` });
      // await AxiosRequest.get("/property/updateUserCredits");
      toast.success("Presentation downloaded successfully");
      setTimeout(() => {
        setIsLoading(false);
        // window.location.reload();
      }, [1000]);
      // } else {
      //   toast.error("Please upgrade your plan for export presentation");
      // }
    } catch (error) {
      setIsLoading(false);
      console.error("Capture Error:", error);
    }
  };

  const handleGeneratePDF = async () => {
    try {
      // if (currentWorkspace?.credits > 0) {
      setIsLoading(true);
      const slides = document.querySelectorAll(".templateBg_2");
      const pdf = new jsPDF({ unit: "px", format: "a4" });

      async function addSlideToPDF(index) {
        if (index < slides.length) {
          const slide = slides[index];
          await processSlide(slide);
          const canvas = await html2canvas(slide, { scale: 2 });

          const pdfWidth = pdf.internal.pageSize.getWidth();
          const pdfHeight = pdf.internal.pageSize.getHeight();
          const imgHeight = (canvas.height * pdfWidth) / canvas.width;
          const topMargin = 20;

          if (index !== 0) {
            pdf.addPage();
          }

          pdf.setFillColor('#fff2f3');
          pdf.rect(0, 0, pdfWidth, pdfHeight, 'F');

          let verticalPosition = topMargin;
          if (imgHeight + topMargin * 2 <= pdfHeight) {
            verticalPosition = (pdfHeight - imgHeight) / 2;
          }

          if (canvas) {
            pdf.addImage(
              canvas.toDataURL("image/jpeg", 1.0),
              "JPEG",
              0,
              verticalPosition / 2,
              pdfWidth,
              imgHeight
            );
          }
          await addSlideToPDF(index + 1);
        } else {
          pdf.save(`${propertyDetails.name}.pdf`);
          toast.success("PDF Download Successfully.");
          setIsLoading(false);
        }
      }

      await addSlideToPDF(0);
      // await AxiosRequest.get("/property/updateUserCredits");
      // } else {
      //     toast.error("Please upgrade your plan for exporting PDF");
      //     setIsLoading(false);
      // }
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to export as PDF. Please try again later.")
      console.error("Error generating PDF:", error);
    }
  };

  return (
    <div className="ppt py-5">
      <div className="container">
        <div className="d-block d-md-flex justify-content-between align-items-center">
          {params && params.id && (
            <Breadcrumb className="breadcrumbCustom fs20">
              <Breadcrumb.Item href={`/property-details/${params.id}`}>Property Details</Breadcrumb.Item>
              <Breadcrumb.Item active>Preview Presentation</Breadcrumb.Item>
            </Breadcrumb>
          )}

          {/* {nearByPlaces && nearByPlaces.length > 0 && staticMapURL && ( */}
          <div>
            <NavDropdown title={template} className="btnBordered">
              <NavDropdown.Item href="#" onClick={() => setTemplate(process.env.REACT_APP_TEMPLATE_1)}>
                {process.env.REACT_APP_TEMPLATE_1}
              </NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={() => setTemplate(process.env.REACT_APP_TEMPLATE_2)}>
                {process.env.REACT_APP_TEMPLATE_2}
              </NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={() => setTemplate(process.env.REACT_APP_TEMPLATE_3)}>
                {process.env.REACT_APP_TEMPLATE_3}
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Export" className="btnBordered ms-4">
              <NavDropdown.Item href="#" onClick={generatePowerPoint}>
                Presentation
              </NavDropdown.Item>
              <NavDropdown.Item href="#" onClick={handleGeneratePDF}>
                PDF
              </NavDropdown.Item>
            </NavDropdown>
          </div>
          {/* )} */}
        </div>
      </div>
      <div className="pptPage">
        <div className="_templatePage">
          <Container>
            {/* slide one start */}
            <section className="_templateSlide templateBg_2 slide_1_b">
              <div className="_bgTransparent _customFlex">
                <div className="logoBar">
                  <span>
                    <img src={realtixLogoOrange} alt="" />
                  </span>
                </div>
                <Row className="g-0">
                  <Col xs={5} sm={5} md={5} lg={5} xl={5} className="leftArea">
                    <div className="content">
                      <h3 className="h3">{propertyDetails.name}</h3>
                      <p className="p">
                        {propertyDetails.type}, {propertyDetails.size.value} {propertyDetails.size.unit}
                      </p>
                      <p className="p">
                        Price : {propertyDetails.priceSymbol}
                        {propertyDetails.price}
                      </p>
                    </div>
                  </Col>
                  <Col xs={7} sm={7} md={7} lg={7} xl={7} className="rightArea">
                    <div className="imgDiv">
                      <div
                        className="imgDiv_imgContainer"
                        style={{
                          backgroundImage:
                            propertyDetails.isVideoGenerate && propertyDetails.coverImage
                              ? `url(${propertyDetails.coverImage})`
                              : `url(${houseImg1})`,
                        }}
                      />
                    </div>
                  </Col>
                </Row>
                <div className="userDetail">
                  <span className="name">{currentUser && currentUser.name}</span>
                  {currentUser && currentUser.mobileNumber && (
                    <span className="mob">Mobile No. : {currentUser.mobileNumber}</span>
                  )}
                  <span className="mob">Email ID : {currentUser && currentUser.email}</span>
                </div>
              </div>
            </section>
            {/* slide two start */}
            <section className="_templateSlide templateBg_2 slide_2">
              <div className="_bgTransparent">
                <div className="_slideTopTitle leftBar">
                  <div className="w-100 d-flex justify-content-between align-items-start">
                    <h3 className="h3 mt-2">Description</h3>
                    {isEdit && isGenerationOwner && (
                      <div className="d-flex justify-content-end align-items-center me-2">
                        <button className="btnSecondary" onClick={() => handleSaveDesc()} style={{ height: '40px' }}>
                          Save
                        </button>
                      </div>
                    )}
                  </div>
                </div>
                <div className="contentArea">
                  {isGenerationOwner ? (
                    <ContentEditable
                      html={(() => {
                        try {
                          // Attempt to parse editedDescription as JSON
                          return editedDescription
                            ? JSON.parse(editedDescription).replace(/(\n\n)/g, '<br/><br/>')
                            : '';
                        } catch (error) {
                          // If parsing fails, treat as plain text
                          return editedDescription
                            ? editedDescription.replace(/(\n\n)/g, '<br/><br/>').replace(/\n/g, '<br/>')
                            : '';
                        }
                      })()}
                      onChange={handleDescriptionChange}
                      onClick={() => setIsEdit(true)}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: (() => {
                          try {
                            // Attempt to parse editedDescription as JSON
                            return editedDescription
                              ? JSON.parse(editedDescription).replace(/(\n\n)/g, '<br/><br/>')
                              : '';
                          } catch (error) {
                            // If parsing fails, treat as plain text
                            return editedDescription
                              ? editedDescription.replace(/(\n\n)/g, '<br/><br/>').replace(/\n/g, '<br/>')
                              : '';
                          }
                        })(),
                      }}
                      onClick={() => setIsEdit(true)}
                    />
                  )}
                </div>
              </div>
            </section>
            {/* slide three start */}
            <section className="_templateSlide templateBg_2 slide_3">
              <div className="_bgTransparent _customFlex">
                <div className="_slideTopTitle leftBar">
                  <h3 className="h3">Property Details</h3>
                </div>
                <Row className="contentArea">
                  <Col xs={5} sm={5} md={5} lg={5} xl={5} className="leftArea_roundImg">
                    <div className="containerDiv">
                      <div className="imgDiv1">
                        <div
                          className="_img"
                          style={{
                            backgroundImage: `url(${
                              propertyDetails.isVideoGenerate && propertyDetails.coverImage
                                ? propertyDetails.coverImage
                                : houseImg1
                            })`,
                          }}
                        />
                      </div>
                      <div className="imgDiv2">
                        <div
                          className="_img"
                          style={{
                            backgroundImage: `url(${
                              propertyDetails.isVideoGenerate ? propertyDetails.propertyImages[0].images[0] : houseImg1
                            })`,
                          }}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col xs={7} sm={7} md={7} lg={7} xl={7} className="rightArea">
                    <Row>
                      <div className="row">
                        <div className="col-4">
                          <div className="iconsDiv">
                            <div className=" icon">
                              <BedRoomIcon />
                            </div>
                            <div className="title1">BedRooms</div>
                            <div className="title2">{propertyDetails.num_bedrooms || 3}</div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="iconsDiv">
                            <div className=" icon">
                              <BathIcon />
                            </div>
                            <div className="title1">Baths</div>
                            <div className="title2">{propertyDetails.num_baths || 3}</div>
                          </div>
                        </div>
                        <div className="col-4 mb-5">
                          <div className="iconsDiv">
                            <div className=" icon">
                              <ParkingIcon />
                            </div>
                            <div className="title1">Parking</div>
                            <div className="title2">
                              {propertyDetails.parking || propertyDetails.parking === 0 ? propertyDetails.parking : 1}
                            </div>
                          </div>
                        </div>
                      </div>
                      <Row className="">
                        {propertyDetails?.isVideoGenerate &&
                          propertyDetails?.propertyImages.map((item, i) => (
                            <Col key={i} xs={4} sm={4} md={4} lg={4} xl={4}>
                              <div className="iconsDiv">
                                <span className="icon">{getIconByName(item.name)}</span>
                                <span className="title1">{item.name}</span>
                                {!propertyDetails?.propertyUrl && (
                                  <span className="title2">
                                    {item.length} x {item.width} {item.sizeUnit}
                                  </span>
                                )}
                              </div>
                            </Col>
                          ))}
                      </Row>
                      <div className="row mt-5">
                        <div className="col-4">
                          <div className="iconsDiv">
                            <div className="icon">
                              <TranscationTypeIcon />
                            </div>
                            <div className="title1">Transcation Type</div>
                            <div className="title2">{propertyDetails.transactionType}</div>
                          </div>
                        </div>
                        <div className="col-4">
                          <div className="iconsDiv">
                            <div className="icon">
                              <PropertySizeIcon />
                            </div>
                            <div className="title1">Property Size</div>
                            <div className="title2">
                              {propertyDetails.size.value} {propertyDetails.size.unit}
                            </div>
                          </div>
                        </div>
                        <div className="col-4 mb-5">
                          <div className="iconsDiv">
                            <div className="icon">
                              <DollarIcon />
                            </div>
                            <div className="title1">Property Price</div>
                            <div className="title2">
                              {propertyDetails.priceSymbol}
                              {propertyDetails.price}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Row>
                  </Col>
                </Row>
              </div>
            </section>
            {propertyDetails?.propertyImages.map((data, index) => {
              return (
                <section className="_templateSlide templateBg_2 slide_4">
                  <div className="_bgTransparent">
                    <div className="_slideTopTitle leftBar">
                      <h3 className="h3">{data.name}</h3>
                    </div>
                    <Row className="contentArea">
                      <Col xs={4} sm={4} md={4} lg={4} xl={4} className="leftArea">
                        <div className="imgDiv">
                          <div
                            className="imgDiv_imgContainer"
                            style={{
                              backgroundImage: data.images[0] ? `url(${data.images[0]})` : `url(${houseImg1})`,
                            }}
                          />
                        </div>
                      </Col>
                      {propertyDetails?.presentation && propertyDetails?.presentation.length > 0 ? (
                        <>
                          {propertyDetails.presentation
                            .filter((presentation) => presentation.type === data.name)
                            .map((presentation, index) => (
                              <Col xs={8} sm={8} md={8} lg={8} xl={8} className="rightArea">
                                <h2 className="title">{presentation.content.title}</h2>
                                <h5 className="title2">{presentation.content.subtitle}</h5>
                                <p className="desc">{presentation.content.description}</p>
                              </Col>
                            ))}
                        </>
                      ) : (
                        ''
                      )}
                    </Row>
                  </div>
                </section>
              );
            })}
            {/* slide six start */}
            {/* <section className="_templateSlide templateBg_2 slide_6">
              <div className="_bgTransparent">
                <Row>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <div className="_slideTopTitle leftBar">
                      <h3 className="h3">Restaurants</h3>
                    </div>
                    <Row className="g-0">
                      {[...Array(2)].map((_, i) => (
                        <Col
                          key={i}
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="px-1"
                        >
                          <div className="cardDiv">
                            <div className="imgDiv">
                              <div
                                className="imgDiv_imgContainer"
                                style={{
                                  backgroundImage:
                                    `url(${houseImg1})`,
                                }}
                              />
                            </div>
                            <div className="cardContent">
                              <h5 className="title">YellowBelly Brewery</h5>
                              <div className="address">
                                <span className="fw-bold">Address : </span>
                                <span>288 Water Street, St. John's</span>
                              </div>
                              <div className="address">
                                <span className="fw-bold">Distance : </span>
                                <span>3.34 km</span>
                              </div>
                              <div className="address">
                                <span className="fw-bold">Rating : </span>
                                <span>
                                  <RatingIcon />
                                </span>
                                <span>2123 Review</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                  <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                    <div className="_slideTopTitle leftBar">
                      <h3 className="h3">Grocery or Supermarkert</h3>
                    </div>
                    <Row className="g-0">
                      {[...Array(2)].map((_, i) => (
                        <Col
                          key={i}
                          xs={6}
                          sm={6}
                          md={6}
                          lg={6}
                          xl={6}
                          className="px-1"
                        >
                          <div className="cardDiv">
                            <div className="imgDiv">
                              <div
                                className="imgDiv_imgContainer"
                                style={{
                                  backgroundImage:
                                    `url(${houseImg1})`,
                                }}
                              />
                            </div>
                            <div className="cardContent">
                              <h5 className="title">Walmart Supercentre</h5>
                              <div className="address">
                                <span className="fw-bold">Address : </span>
                                <span>75 Kelsey Drive, St. John's</span>
                              </div>
                              <div className="address">
                                <span className="fw-bold">Distance : </span>
                                <span>4 km</span>
                              </div>
                              <div className="address">
                                <span className="fw-bold">Rating : </span>
                                <span>
                                  <RatingIcon />
                                </span>
                                <span>222 Review</span>
                              </div>
                            </div>
                          </div>
                        </Col>
                      ))}
                    </Row>
                  </Col>
                </Row>
              </div>
            </section> */}
            {/* slide sevel start */}
            {nearByPlaces.length > 0 && (
              <section
                className={
                  nearByPlaces.some((data) => data.type === 'restaurant' || data.type === 'grocery_or_supermarket')
                    ? '_templateSlide templateBg_2 slide_6'
                    : 'd-none'
                }
              >
                <div className="_bgTransparent">
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <div className="_slideTopTitle leftBar">
                        <h3 className="h3">Restaurants</h3>
                      </div>
                      <Row className="g-0">
                        {nearByPlaces.map((data, index) => {
                          return (
                            <>
                              {data.type === 'restaurant' && (
                                <Col key={index} xs={6} sm={6} md={6} lg={6} xl={6} className="px-1">
                                  <div className="cardDiv">
                                    <div className="imgDiv">
                                      <div
                                        className="imgDiv_imgContainer"
                                        style={{
                                          backgroundImage: imageSrc[index]
                                            ? `url(${imageSrc[index]})`
                                            : `url(${houseImg1})`,
                                        }}
                                      />
                                    </div>
                                    <div className="cardContent">
                                      <h5 className="title">{data.name}</h5>
                                      <div className="address">
                                        <span className="fw-bold">Address : </span>
                                        <span>{data.vicinity}</span>
                                      </div>
                                      <div className="address">
                                        <span className="fw-bold">Distance : </span>
                                        <span>{data.distance} km</span>
                                      </div>
                                      <div className="address">
                                        <span className="fw-bold">Rating : </span>
                                        <span>{generateStars(data.rating)}</span>
                                        <span>{data.user_ratings_total || 0} Review</span>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              )}
                            </>
                          );
                        })}
                      </Row>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <div className="_slideTopTitle leftBar">
                        <h3 className="h3">Walmart Supercentre</h3>
                      </div>
                      <Row className="g-0">
                        {nearByPlaces.map((data, index) => {
                          return (
                            <>
                              {data.type === 'grocery_or_supermarket' && (
                                <Col key={index} xs={6} sm={6} md={6} lg={6} xl={6} className="px-1">
                                  <div className="cardDiv">
                                    <div className="imgDiv">
                                      <div
                                        className="imgDiv_imgContainer"
                                        style={{
                                          backgroundImage: imageSrc[index]
                                            ? `url(${imageSrc[index]})`
                                            : `url(${houseImg1})`,
                                        }}
                                      />
                                    </div>
                                    <div className="cardContent">
                                      <h5 className="title">{data.name}</h5>
                                      <div className="address">
                                        <span className="fw-bold">Address : </span>
                                        <span>{data.vicinity}</span>
                                      </div>
                                      <div className="address">
                                        <span className="fw-bold">Distance : </span>
                                        <span>{data.distance} km</span>
                                      </div>
                                      <div className="address">
                                        <span className="fw-bold">Rating : </span>
                                        <span>{generateStars(data.rating)}</span>
                                        <span>{data.user_ratings_total || 0} Review</span>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              )}
                            </>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </section>
            )}
            {/* slide sevel start */}
            {nearByPlaces.length > 0 && (
              <section
                className={
                  nearByPlaces.some((data) => data.type === 'school' || data.type === 'hospital')
                    ? '_templateSlide templateBg_2 slide_6'
                    : 'd-none'
                }
              >
                <div className="_bgTransparent">
                  <Row>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <div className="_slideTopTitle leftBar">
                        <h3 className="h3">School</h3>
                      </div>
                      <Row className="g-0">
                        {nearByPlaces.map((data, index) => {
                          return (
                            <>
                              {data.type === 'school' && (
                                <Col key={index} xs={6} sm={6} md={6} lg={6} xl={6} className="px-1">
                                  <div className="cardDiv">
                                    <div className="imgDiv">
                                      <div
                                        className="imgDiv_imgContainer"
                                        style={{
                                          backgroundImage: imageSrc[index]
                                            ? `url(${imageSrc[index]})`
                                            : `url(${houseImg1})`,
                                        }}
                                      />
                                    </div>
                                    <div className="cardContent">
                                      <h5 className="title">{data.name}</h5>
                                      <div className="address">
                                        <span className="fw-bold">Address : </span>
                                        <span>{data.vicinity}</span>
                                      </div>
                                      <div className="address">
                                        <span className="fw-bold">Distance : </span>
                                        <span>{data.distance} km</span>
                                      </div>
                                      <div className="address">
                                        <span className="fw-bold">Rating : </span>
                                        <span>{generateStars(data.rating)}</span>
                                        <span>{data.user_ratings_total || 0} Review</span>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              )}
                            </>
                          );
                        })}
                      </Row>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} xl={6}>
                      <div className="_slideTopTitle leftBar">
                        <h3 className="h3">Hospital</h3>
                      </div>
                      <Row className="g-0">
                        {nearByPlaces.map((data, index) => {
                          return (
                            <>
                              {data.type === 'hospital' && (
                                <Col key={index} xs={6} sm={6} md={6} lg={6} xl={6} className="px-1">
                                  <div className="cardDiv">
                                    <div className="imgDiv">
                                      <div
                                        className="imgDiv_imgContainer"
                                        style={{
                                          backgroundImage: imageSrc[index]
                                            ? `url(${imageSrc[index]})`
                                            : `url(${houseImg1})`,
                                        }}
                                      />
                                    </div>
                                    <div className="cardContent">
                                      <h5 className="title">{data.name}</h5>
                                      <div className="address">
                                        <span className="fw-bold">Address : </span>
                                        <span>{data.vicinity}</span>
                                      </div>
                                      <div className="address">
                                        <span className="fw-bold">Distance : </span>
                                        <span>{data.distance} km</span>
                                      </div>
                                      <div className="address">
                                        <span className="fw-bold">Rating : </span>
                                        <span>{generateStars(data.rating)}</span>
                                        <span>{data.user_ratings_total || 0} Review</span>
                                      </div>
                                    </div>
                                  </div>
                                </Col>
                              )}
                            </>
                          );
                        })}
                      </Row>
                    </Col>
                  </Row>
                </div>
              </section>
            )}
            <section className="_templateSlide templateBg_2 slide_4">
              <div className="_bgTransparent">
                <div className="_slideTopTitle leftBar">
                  <h3 className="h3">Contact Us</h3>
                </div>
                <Row className="contentArea px-5">
                  <Col
                    xs={7}
                    sm={7}
                    md={7}
                    lg={7}
                    xl={7}
                    className="rightArea d-flex justify-content-center flex-column"
                  >
                    <div className="font50Orange mb-4">{currentUser.name}</div>
                    <div className="font24BlackPPT mb-4" style={{ fontWeight: '400', wordBreak: 'break-word' }}>
                      {propertyDetails?.address}
                    </div>
                    <div className="font20BlackPPT">
                      {currentUser.mobileNumber && (
                        <div>
                          Mobile No.: <span className="font-bold">{currentUser.mobileNumber}</span>
                        </div>
                      )}
                      <div>
                        Email Id: <span className="font-bold">{currentUser.email}</span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={5} sm={5} md={5} lg={5} xl={5} className="leftArea">
                    <div className="imgDiv">
                      <img className="" src={ContactUsImg} alt="" width="100%" height="100%" />
                    </div>
                  </Col>
                </Row>
              </div>
            </section>
            {/* slide eight start */}
            {/* <section className="_templateSlide templateBg_2 slide_6">
              <div className="_bgTransparent">
                <div className="_slideTopTitle leftBar">
                  <h3 className="h3">Map Location</h3>
                </div>
                <div className="">
                  <LoadScript
                    googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_API_KEY}`}
                  >
                    <GoogleMap
                      mapContainerStyle={mapContainerStyle}
                      center={coordinates}
                      zoom={15}
                    >
                      <Marker position={coordinates} />
                    </GoogleMap>
                  </LoadScript>
                </div>
              </div>
            </section> */}
          </Container>
        </div>
      </div>
    </div>
  );
};

export default Template3;
